@import "../../../../../assets/scss/typography";
@import "../../../../AppForm/assets/scss/style.scss";

.app-search-bar {
    width: 30rem;
    height: fit-content;

    @extend .app-form-input;

    &--icon {
        @extend .app-form-input--icon--left;
    }

    &--input {
        @extend .app-form-input--with-icon--left;

        &:focus {
            border-color: var(--app-neutral-300) !important;
            outline: none !important;
        }
    }
}
