@import "../../../../assets/scss/mixins";

.app-progressive-tabs {
    display: flex;
    align-items: center;
    justify-content: center;

    .onboard-tab {
        position: relative;

        &--icon {
            width: 4.5rem;
            height: 4.5rem;
            background-color: var(--app-text-icon-primary-white);
            border: 0.8rem solid var(--app-neutral-300);
            border-radius: 50%;
            position: relative;

            &.active {
                border: 0.8rem solid var(--app-primary-color);
            }

            &.completed {
                background-color: var(--app-primary-color);
                border: 0.8rem solid var(--app-primary-color);

                .tab-icon-svg {
                    display: block;
                }
            }

            .tab-icon-svg {
                @include absolute;
                display: none;
                background-color: var(--app-text-icon-primary-white);
            }
        }

        &--name {
            color: var(--app-neutral-700);
            font-size: var(--app-font-size-16);
            font-weight: var(--app-font-weight-bold);
            line-height: var(--app-font-size-16);
            @include absolute(125%, 50%);
            width: max-content;
        }
    }

    .tab-separator {
        background-color: var(--app-neutral-300);
        height: 0.5rem;
        width: 10rem;

        &:last-child {
            display: none;
        }

        &.completed {
            background-color: var(--app-primary-color);
        }
    }
}
