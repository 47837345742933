.map-color-dropdown {
    cursor: pointer;

    &--content {
        &--row {
            display: inline-block;
            width: fit-content;
        }
    }
}

.popover-container-vote-color {
    border: var(--app-action-drop-down-border);
    width: fit-content;
    padding: var(--app-action-drop-down-padding);
    box-shadow: var(--app-box-shadow-400);
}
