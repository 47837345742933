@import "../../../../../assets/scss/mixins";
@import "../../../../../assets/scss/typography";

.app-map-page-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--app-section-padding);

    &--left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: var(--app-text-icon-primary-white);
        padding: 0.8rem 1.6rem;
        flex: 1;
        height: fit-content;

        &--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            gap: var(--app-gap-2);

            .title {
                .paragraph--100 {
                    @include text-overflow(1);
                }
            }
        }

        &--content {
            .subtitle {
                @include text-overflow(2);
            }

            .description {
                @include text-overflow(3);
            }
        }
    }

    &--center {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: start;
    }

    &--right {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .print-area {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0.5rem;
        left: 0.5rem;
        transition: all 0.2s ease-out;

        &--a4 {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            border: 0.1rem solid var(--app-neutral-600);

            &--bg {
                background-image: linear-gradient(
                    var(--app-neutral-1000) 5rem,
                    var(--app-neutral-800) 8rem,
                    var(--app-neutral-100)
                );

                opacity: 0.6;
                z-index: 6;
                width: 100%;
                height: 100%;
            }

            &--content {
                position: absolute;
                top: 1.5rem;
                right: 1.5rem;

                display: flex;
                gap: var(--app-gap-4);

                .form-label,
                .form-check-label {
                    color: var(--app-text-icon-primary-white) !important;
                }
            }
        }

        // change slider width when print area is active
        .app-zoom-slider {
            width: 25rem;
        }
    }

    .voting-help-icon {
        cursor: pointer;
        margin-right: 1rem;
        .icon {
            width: 2.4rem;
            height: 2.4rem;
        }
    }
}

.map-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 2.5rem 0 0;
    gap: var(--app-gap-3);
    text-align: start;

    .sub-title {
        align-self: self-start;

        @extend .paragraph--300;
        @extend .font-weight--bold;
    }

    .description {
        align-self: self-start;

        @extend .paragraph--300;
        @extend .font-weight--light;
    }
}
