input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    width: var(--app-icon-height);
    height: var(--app-icon-height);
    background-image: url("../../assets/icons/close.icon.svg");
}

// to change password dots
// input[type="password" i] {
//     font-family: sans-serif;
// }
