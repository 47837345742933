@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/typography";

.app-avatar {
    display: flex;
    cursor: pointer;
    height: var(--app-avatar-height);

    &--img-container {
        height: inherit;
        width: var(--app-avatar-height);

        &--img {
            border-radius: 50%;
            width: inherit;
            height: inherit;
            border: 0.1rem solid var(--app-text-icon-primary-white);
        }

        &--initials {
            border-radius: 50%;
            width: inherit;
            height: inherit;
            border: 0.1rem solid var(--app-text-icon-primary-white);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--app-text-icon-secondary-black);
            background-color: var(--app-neutral-300);
            font-weight: var(--app-font-weight-bold);
            font-size: var(--app-font-size-16);
        }

        &--status {
            border-radius: 50%;
            height: var(--app-avatar-badge-height);
            width: var(--app-avatar-badge-height);
            display: block;

            @include absolute(85%, 80%);

            outline: 0.2rem solid var(--app-text-icon-primary-white);
            background-color: var(--app-neutral-300);

            &.online {
                background-color: var(--app-success-color);
            }
        }
    }

    &--detail {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .full-name {
            @extend .paragraph--200;
            @extend .font-weight--bold;
            @include text-overflow(1);
        }

        .company-name {
            @extend .paragraph--300;
            @extend .font-weight--light;
            @include text-overflow(1);
        }
    }
}
