@import "../../../../../assets/scss/mixins";
@import "../../../../../assets/scss/typography";

.theme-card {
    border-radius: 0.4rem;
    background-color: var(--app-text-icon-primary-white);
    padding: 2rem;
    overflow: hidden;
    min-height: 20rem;

    &--header {
        .title {
            @extend .font-weight--semi-bold;
            @extend .headline--200;
            color: var(--app-black-color);
        }
    }

    &--info {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &--details {
            min-width: 25rem;
            max-width: 30rem;

            .detail-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 2.2rem;

                border-top: 0.1rem solid var(--app-neutral-300);

                &:last-child {
                    border-bottom: 0.1rem solid var(--app-neutral-300);
                }

                &--header {
                    display: flex;
                    align-items: center;
                    gap: var(--app-gap-3);

                    .title {
                        @extend .paragraph--200;
                        @extend .font-weight--light;
                        @include text-overflow(1);
                    }

                    .color {
                        width: 1.2rem;
                        height: 1.2rem;
                        border-radius: 0.2rem;
                        flex-shrink: 0;

                        &.selected {
                            width: 0.9rem;
                            height: 0.9rem;
                            outline: 0.1rem solid;
                            outline-offset: 0.15rem;
                            margin-left: 0.15rem;
                        }
                    }
                }

                &--info {
                    @extend .paragraph--200;
                    @extend .font-weight--bold;
                    padding: 0 1rem;
                }
            }
        }
    }
}
