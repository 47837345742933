.link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--app-link-font-weight);
    text-decoration: none;
    font-size: var(--app-font-size-16);
    color: var(--app-text-icon-primary-white);
    line-height: var(--app-font-size-16);
    padding: var(--app-link-padding);
    outline: var(--app-link-outline);
    outline-offset: var(--app-link-outline-offset);

    &.link-sm {
        font-size: var(--app-font-size-12);
        line-height: var(--app-font-size-12);
    }

    &.link-primary {
        color: var(--app-primary-color) !important;
        border-bottom: var(--app-link-border-width) solid
            var(--app-primary-color);

        i {
            background-color: var(--app-primary-color);
        }

        &:hover {
            color: var(--app-primary-color-hover) !important;
            border-bottom: var(--app-link-border-width) solid
                var(--app-primary-color-hover);

            i {
                background-color: var(--app-primary-color-hover);
            }
        }

        &:active {
            color: var(--app-primary-color-pressed) !important;
            border-bottom: var(--app-link-border-width) solid
                var(--app-primary-color-pressed);

            i {
                background-color: var(--app-primary-color-pressed);
            }
        }

        &:focus-visible {
            color: var(--app-primary-color-hover) !important;
            border-bottom: var(--app-link-border-width) solid
                var(--app-primary-color-hover);
            background-color: var(--app-primary-color-bg);

            i {
                background-color: var(--app-primary-color-hover);
            }
        }
    }

    &.link-secondary {
        color: var(--app-text-icon-secondary-black) !important;
        border-bottom: var(--app-link-border-width) solid
            var(--app-text-icon-secondary-black);

        i {
            background-color: var(--app-text-icon-secondary-black);
        }

        &:hover {
            color: var(--app-neutral-800) !important;
            border-bottom: var(--app-link-border-width) solid
                var(--app-neutral-800);

            i {
                background-color: var(--app-neutral-800);
            }
        }

        &:active {
            color: var(--app-text-icon-primary-black) !important;
            border-bottom: var(--app-link-border-width) solid
                var(--app-text-icon-primary-black);

            i {
                background-color: var(--app-text-icon-primary-black);
            }
        }

        &:focus-visible {
            color: var(--app-text-icon-primary-black) !important;
            border-bottom: var(--app-link-border-width) solid
                var(--app-text-icon-primary-black);
            background-color: var(--app-neutral-200);

            i {
                background-color: var(--app-text-icon-primary-black);
            }
        }
    }

    &.link-disabled {
        cursor: no-drop;
        color: var(--app-text-icon-disabled-black) !important;
        border-bottom: var(--app-link-border-width-disabled);

        i {
            background-color: var(--app-text-icon-disabled-black);
        }

        &:hover,
        &:active,
        &:focus-visible {
            @extend .link-disabled;
        }
    }
}
