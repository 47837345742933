@import "../../../../../assets/scss/typography";

.map-size-dropdown {
    cursor: pointer;

    &--action {
        display: flex;
        height: 1.6rem;
        padding: 0.1rem 0.5rem;
        border-radius: 0.3rem;
        background-color: var(--app-primary-color);

        .title {
            color: var(--app-text-icon-primary-white);
            @extend .paragraph--300;
            @extend .font-weight--bold;
            user-select: none;
        }
    }
}

.popover-container-size {
    border: var(--app-action-drop-down-border);
    width: calc(var(--app-action-drop-down-min-width) - 5rem);
    padding: var(--app-action-drop-down-padding);
    box-shadow: var(--app-box-shadow-400);
}
