@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

:root {
    --map-board-container-padding: 1rem;
    --map-board-container-width: 35rem;
    --map-board-container-margin: 2rem;
    --map-portfolio-container-padding-2: 3rem;
    --map-portfolio-card-height-2: 15rem;
    --map-portfolio-card-width-2: 29rem; // (--map-board-container-width - 2*--map-portfolio-container-padding-2)
}

.map-kanban-page {
    &--container {
        display: flex;
        flex-direction: column;

        @include scroll-style($height: 0.5rem) {
            overflow-x: auto;
            overflow-y: hidden;
        }

        &--boards {
            display: flex;
            gap: var(--app-gap-5);

            .board-wrapper {
                display: flex;
                gap: var(--app-gap-5);

                .board-container {
                    display: flex;
                    flex-direction: column;
                    background-color: var(--app-text-icon-primary-white);
                    box-shadow: var(--app-box-shadow-100);
                    min-width: var(--map-board-container-width);
                    border-radius: 0.4rem 0.4rem 0 0;
                    margin: var(--map-board-container-margin) 0;
                    margin-top: 0;

                    .board-container--item {
                        border-top-left-radius: 0.4rem;
                        border-top-right-radius: 0.4rem;
                    }

                    &.backlog {
                        .board-container--item,
                        .portfolio-container--item--action {
                            background-image: var(--app-gradient-primary-color);
                        }
                    }

                    &.inprogress {
                        .board-container--item,
                        .portfolio-container--item--action {
                            background-image: var(
                                --app-gradient-secondary-color
                            );
                        }

                        .portfolio-container--item--content {
                            .detail {
                                .description {
                                    @include text-overflow(3);
                                }
                            }
                        }
                    }

                    &.completed {
                        .board-container--item,
                        .portfolio-container--item--action {
                            background-image: var(--app-gradient-green-color);
                        }
                    }

                    &--item {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        height: 10rem;
                        background-image: var(--app-gradient-neutral-color);
                        padding: var(--map-board-container-padding)
                            var(--map-board-container-padding) 0
                            var(--map-board-container-padding);
                        border-radius: 0.4rem;

                        &--header {
                            //
                        }

                        &--content {
                            display: flex;
                            gap: var(--app-gap-3);
                            align-items: center;

                            .title {
                                @extend .headline--200;
                                @extend .font-weight--semi-bold;
                                @include text-overflow(1);
                            }
                        }

                        &--action {
                            .total-cards {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 3.2rem;
                                width: 3.2rem;
                                border-radius: 50%;
                                background-color: var(
                                    --app-text-icon-primary-white
                                );

                                .title {
                                    @extend .paragraph--200;
                                    @extend .font-weight--bold;

                                    color: var(--app-text-icon-primary-black);

                                    @include text-overflow(1);
                                }
                            }
                        }
                    }

                    .portfolio-container {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        align-items: center;
                        gap: var(--app-gap-4);
                        padding: var(--map-portfolio-container-padding-2);
                        padding-top: 0;
                        margin-top: -4rem;

                        &--item {
                            display: flex;
                            flex-direction: column;
                            min-height: var(--map-portfolio-card-height-2);
                            width: var(--map-portfolio-card-width-2);
                            box-shadow: var(--app-box-shadow-100);
                            background-color: var(
                                --app-text-icon-primary-white
                            );

                            &.active-item {
                                outline: 0.2rem solid var(--app-primary-color);
                            }

                            &.filter-item {
                                outline: 0.2rem solid var(--app-primary-color);
                            }

                            &.un-filter-item {
                                background-color: var(
                                    --map-card-disabled-color
                                );
                                opacity: 0.5;
                            }

                            &--action {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                height: 2.4rem;
                                min-height: 2.4rem;
                                padding: var(--map-card-action-padding);
                                background-image: var(
                                    --app-gradient-neutral-color
                                );
                            }

                            &--content {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                flex-grow: 1;
                                padding: var(--map-card-content-padding);
                                gap: var(--app-gap-2);
                                position: relative;

                                .detail {
                                    display: flex;
                                    flex-direction: column;
                                    gap: var(--app-gap-2);

                                    .title {
                                        @extend .label--300;
                                        @include text-overflow(2);
                                        line-height: var(--app-font-size-16);

                                        &--full-height {
                                            &--with-responsible {
                                                @include text-overflow(4);
                                            }
                                            @include text-overflow(10);
                                        }
                                    }

                                    .description {
                                        @extend .paragraph--300;
                                        @extend .font-weight--light;
                                        @include text-overflow(7);
                                    }
                                }

                                .info {
                                    display: flex;
                                    flex-direction: column;
                                    gap: var(--app-gap-2);

                                    .info-date-with-average {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        .due-date {
                                            padding-left: 1.2rem;
                                            display: flex;
                                            align-items: center;
                                            @extend .paragraph--300;
                                        }

                                        .average-valuation {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            height: 3.2rem;
                                            width: 3.2rem;
                                            border-radius: 50%;
                                            background-color: var(
                                                --app-warning-color
                                            );

                                            .title {
                                                @extend .paragraph--200;
                                                @extend .font-weight--bold;

                                                color: var(
                                                    --app-text-icon-primary-white
                                                );

                                                @include text-overflow(1);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .board-separator {
                    border: 0.1rem dashed var(--app-neutral-800);
                }

                &:last-child {
                    .board-separator {
                        border: none;
                    }
                }
            }
        }
    }
}
