@import "../../../../assets/scss/mixins";

.app-slider {
    display: flex;
    align-items: center;
    width: 100%;

    .rc-slider {
        padding: 0.3rem 0;
        border-radius: 1.6rem;

        .rc-slider-rail {
            height: 0.8rem;
            background-color: var(--app-text-icon-primary-white);
            border-radius: 1.6rem;
            box-shadow: var(--app-box-shadow-300);
        }

        .rc-slider-track {
            height: 0.8rem;
            background-color: var(--app-primary-color);
            border-radius: 1.6rem;
        }

        .rc-slider-step {
            height: 0.8rem;
        }

        .rc-slider-handle {
            height: 2rem;
            width: 2rem;
            margin-top: -0.6rem;
            background-color: var(--app-text-icon-primary-white);
            opacity: 1;
            border: 0.2rem solid var(--app-primary-color);
            outline: none;

            &:hover {
                box-shadow: 0 0 0.5rem var(--app-primary-color-hover);
            }

            &:active,
            &:focus-visible {
                box-shadow: 0 0 0.5rem var(--app-primary-color-pressed);
            }
        }
    }
}
