@import "typography";

.cursor-pointer {
    cursor: pointer;
}

// Spacings
.mt-10 {
    margin-top: 10rem;
}

// sweetalert2 style overrides
.swal-custom-popup {
    box-shadow: none !important;
}

.swal-custom-title {
    font-size: var(--app-font-size-16) !important;
    @extend .font-weight--bold;
    margin: 0.2rem 0 0 1rem !important;
}

.swal-custom-html-container {
    font-size: var(--app-font-size-14) !important;
    margin: 0 0 0 1rem !important;
}

.swal-custom-icon {
    grid-row: 1 !important;
    margin-left: 0 !important;
}

.swal2-success {
    border-color: var(--app-success-color) !important;
}
.swal2-success-line-long,
.swal2-success-line-tip {
    background-color: var(--app-success-color) !important;
}
