@import "bootstrap";
@import "variables";
@import "base";
@import "fonts";
@import "mixins";
@import "screen_size";
@import "animations";
@import "typography";
@import "custom";
@import "misc";

#root {
    @include scroll-style($width: 0.5rem) {
        overflow-x: hidden;
        overflow-y: auto;
    }
}
