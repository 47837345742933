.email-template-page {
    &--form {
        .placeholders-container {
            &--content {
                display: flex;
                flex-wrap: wrap;

                &--item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: var(--app-neutral-300);
                    padding: 0.5rem 1rem;
                    border-radius: 1.5rem;
                    cursor: pointer;
                }
            }
        }
    }
}
