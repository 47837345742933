:root {
    // default color
    --app-white-color: #fff;
    --app-black-color: #000;

    // primary color
    --app-primary-color: #ed2f52;
    --app-primary-color-bg: #fff0f3;
    --app-primary-color-hover: #ae233c;
    --app-primary-color-pressed: #7e192c;

    // secondary color
    --app-secondary-color: #8b54f7;
    --app-secondary-color-bg: #f8f5ff;
    --app-secondary-color-hover: #6d35de;
    --app-secondary-color-pressed: #5221b5;

    // success
    --app-success-color: #55d9a9;
    --app-success-color-bg: #edfdf7;
    --app-success-color-hover: #24966c;
    --app-success-color-pressed: #004a2f;

    // warning
    --app-warning-color: #fc9d4e;
    --app-warning-color-bg: #fff4eb;
    --app-warning-color-hover: #b87339;
    --app-warning-color-pressed: #663f20;

    // danger
    --app-danger-color: #3f444d; // --app-neutral-300
    --app-danger-color-bg: #e1e6ef; // --app-neutral-700
    --app-danger-color-hover: #23272f; // --app-neutral-800
    --app-danger-color-pressed: #1b1f27; // --app-neutral-900

    // other color
    --app-yellow-color: #ffe65d;

    // gradient
    --app-gradient-neutral-color: linear-gradient(#6b728050, #6b728001);
    --app-gradient-primary-color: linear-gradient(#3c7af650, #3c7af601);
    --app-gradient-secondary-color: linear-gradient(#8e5af650, #8e5af601);
    --app-gradient-green-color: linear-gradient(#0ea47250, #0ea47201);
    --app-gradient-yellow-color: linear-gradient(#f5ba0a50, #f5ba0a01);
    --app-gradient-red-color: linear-gradient(#ef435250, #ef435201);

    // neutral
    --app-neutral-100: #f8f9fc;
    --app-neutral-200: #f1f3f9;
    --app-neutral-300: #e1e6ef;
    --app-neutral-600: #5f6c85;
    --app-neutral-700: #3f444d;
    --app-neutral-800: #23272f;
    --app-neutral-900: #1b1f27;
    --app-neutral-1000: #140d0a;

    // text icon
    --app-text-icon-primary-black: #1d2433;
    --app-text-icon-secondary-black: #1d243380;
    --app-text-icon-disabled-black: #1d243365;
    --app-text-icon-primary-white: var(--app-white-color);
    --app-text-icon-secondary-white: #ffffff75;
    --app-text-icon-disabled-white: #ffffff60;

    // font family
    --app-font-family: "Titillium Web", sans-serif;

    // font size
    --app-font-size-10: 1rem;
    --app-font-size-12: 1.2rem;
    --app-font-size-13: 1.3rem;
    --app-font-size-14: 1.4rem;
    --app-font-size-15: 1.5rem;
    --app-font-size-16: 1.6rem; // default
    --app-font-size-18: 1.8rem;
    --app-font-size-20: 2rem;
    --app-font-size-24: 2.4rem;
    --app-font-size-28: 2.8rem;
    --app-font-size-36: 3.6rem;
    --app-font-size-40: 4rem;
    --app-font-size-60: 6rem;

    // font weight
    --app-font-weight-light: 300;
    --app-font-weight-semi-bold: 600;
    --app-font-weight-bold: 700;

    // box-shadow
    --app-box-shadow-100: 0 0.2rem 0.8rem 0 rgb(0 0 0 / 12%);
    --app-box-shadow-200: 0 0.4rem 1.2rem 0 rgb(0 0 0 / 16%);
    --app-box-shadow-300: 0 0.8rem 1.6rem 0 rgb(0 0 0 / 12%);
    --app-box-shadow-400: 0 0.8rem 3.2rem 0 rgb(0 0 0 / 16%);

    // z-index
    --app-z-index-bg-image: -100;
    --app-z-index-normal: 1;
    --app-z-index-dropdown: 1000;
    --app-z-index-sticky: 1020;
    --app-z-index-fixed: 1030;
    --app-z-index-offcanvas-backdrop: 1040;
    --app-z-index-offcanvas: 1045;
    --app-z-index-modal-backdrop: 1050;
    --app-z-index-modal: 1055;
    --app-z-index-popover: 1070;
    --app-z-index-tooltip: 1080;
    --app-z-index-toast: 1090;
    --app-z-index-loader: 1100;

    // button
    --app-button-height: 4rem;
    --app-button-height-sm: 3.6rem;
    --app-button-padding: 0.8rem 1.6rem;
    --app-button-padding-sm: 0.4rem 1.2rem;
    --app-button-border-radius: 0.4rem;
    --app-button-font-weight: var(--app-font-weight-bold);
    --app-button-outline: 0;
    --app-button-outline-offset: 0.2rem;
    --app-button-outline-width-focus: 0.2rem;
    --app-button-opacity-disabled: 1;
    --app-button-box-shadow: none;
    --app-button-icon-height: 4rem;
    --app-button-icon-height-sm: 2.4rem;
    --app-button-icon-padding: 0.6rem;
    --app-button-icon-padding-sm: 0.4rem;
    --app-button-icon-padding-circle: 0rem;

    // icon
    --app-icon-height-xs: 1.4rem;
    --app-icon-height-sm: 1.6rem;
    --app-icon-height: 1.8rem;
    --app-icon-height-lg: 2rem;
    --app-icon-height-xl: 2.2rem;
    --app-icon-height-xxl: 2.6rem;

    // icon flag
    --app-flag-height: 1.5rem;
    --app-flag-width: 2.5rem;

    // avatar
    --app-avatar-height: 4rem;
    --app-avatar-height-sm: 3.2rem;
    --app-avatar-badge-height: 1rem;

    // loader
    --app-loader-height: 6.2rem;
    --app-loader-height-sm: 2.4rem;
    --app-loader-height-lg: 11.2rem;

    // link
    --app-link-padding: 0.2rem 0;
    --app-link-font-weight: var(--app-font-weight-semi-bold);
    --app-link-border-width: 0.1rem;
    --app-link-border-width-disabled: 0;
    --app-link-outline: 0;
    --app-link-outline-offset: 0;

    // form input
    --app-form-input-height: 4rem;
    --app-form-input-box-shadow: none;
    --app-form-input-outline-width-focus: 0.1rem;

    // form label
    --app-form-label-font-weight: var(--app-font-weight-semi-bold);

    // form checkbox
    --app-form-checkbox-height: 1.8rem;

    // form upload
    --app-form-upload-border-radius: 0.4rem;
    --app-form-upload-border-width: 0.2rem;
    --app-form-upload-border-color: var(--app-neutral-700);

    // alert bar
    --app-alert-bar-padding: 0.6rem 0.8rem;
    --app-alert-bar-padding-lg: 1.6rem;
    --app-alert-bar-border-radius: 0.8rem;

    // progress bar
    --app-progress-bar-height: 0.8rem;
    --app-progress-bar-height--lg: 1.2rem;
    --app-progress-bar-border-radius: 3.2rem;

    // scrollbar
    --app-scrollbar-border-radius: 0.5rem;
    --app-scrollbar-width: 0.3rem;
    --app-scrollbar-height: 0.3rem;

    // section
    --app-section-padding: 2rem;

    // modal
    --app-modal-padding: 1rem;
    --app-modal-border: none;

    // modal drawer
    --app-modal-drawer-width: 40rem;
    --app-modal-drawer-border-radius: 0;

    // action drop down
    --app-action-drop-down-border: none;
    --app-action-drop-down-min-width: 18rem;
    --app-action-drop-down-max-width: 30rem;
    --app-action-drop-down-padding: 1rem 0;

    // bootstrap gap
    --app-gap-1: 0.25rem; // gap-1
    --app-gap-2: 0.5rem; // gap-2
    --app-gap-3: 1rem; // gap-3
    --app-gap-4: 1.5rem; // gap-4
    --app-gap-5: 3rem; // gap-5

    // header
    --app-header-height: 7rem;

    // Modal top space
    --app-modal-top-spacing: 15.5rem;
}
