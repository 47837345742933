@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

// with BEM technique
.subscription-review-info {
    display: grid;
    grid-template-columns: 1fr minmax(30rem, 20vw);
    grid-template-rows: min-content;
    grid-column-gap: 2.4rem;

    &__review {
        .highlight {
            @extend .headline--300;
            @extend .font-weight--bold;
            padding: 0.8rem 1.6rem;
            background-color: var(--app-neutral-300);
        }

        .separator-line {
            border-bottom: 0.1rem dashed var(--app-text-icon-primary-black);
        }
    }

    &__sidebar {
        position: sticky;
        top: 10rem;
        max-height: 66vh;

        padding: 1.6rem;
        border-radius: 0.3rem;
        box-shadow: var(--app-box-shadow-100);
        background-color: var(--app-text-icon-primary-white);
        display: flex;
        flex-direction: column;

        .separator-line {
            border-bottom: 0.1rem solid var(--app-text-icon-primary-black);
        }
    }
}

.onboard-page--card {
    .subscription-review-info {
        &__sidebar {
            top: 0;
        }
    }

    .onboarding-page--client {
        section.app-uploader {
            width: 50%;
        }
    }
}
