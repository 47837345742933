@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

// with BEM technique
.subscription-chart-info {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .map-pie-chart {
        margin: 0;
        margin-top: 1rem;
    }

    .chart-title {
        @include absoluteAll(15%, 35%);

        @extend .headline--300;
        @extend .font-weight--bold;
    }

    .chart-btn {
        @include absoluteAll(null, 50%, null, -10%);
        z-index: var(--app-z-index-fixed);
    }
}
