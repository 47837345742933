.app-grid-on-board-status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: var(--app-warning-color);

    &.onboard {
        background-color: var(--app-success-color);
    }
}
