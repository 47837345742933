.app-uploader {
    &--container {
        aspect-ratio: 16 / 9;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: var(--app-neutral-100);
        border-radius: var(--app-form-upload-border-radius);
        border: var(--app-form-upload-border-width) dashed
            var(--app-form-upload-border-color);
        overflow: hidden;

        .preview {
            width: 100%;
            height: 100%;

            &--img {
                // aspect-ratio: 16 / 9;
                // object-fit: scale-down;
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }
}
