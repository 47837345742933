*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%; // 10px === 1rem (default 16px === 1rem) // font-size: var(--app-font-size-16);
}

body {
    font-family: var(--app-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    min-height: 100%;
    color: var(--app-text-icon-primary-black);
    background-color: var(--app-neutral-200);
    overflow: hidden; // for flickering issue in action drop down
}

#root {
    height: 100%;
}
