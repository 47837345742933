// tiny mce
.tox-tinymce {
    border: 0.1rem solid var(--app-neutral-300) !important;
    border-radius: var(--bs-border-radius) !important;
}

.tox-dialog-wrap {
    font-family: var(--app-font-family) !important;
    .tox-dialog__header {
        .tox-dialog__title {
            display: flex;
            gap: var(--app-gap-3);
            align-items: center;
            font-size: var(--app-font-size-24);
            font-weight: var(--app-font-weight-semi-bold);
            font-family: var(--app-font-family);

            &::before {
                content: "";
                display: block;
                height: 2.4rem;
                width: 2.4rem;
                background-color: var(--app-black-color);
                mask-image: url("../icons/info.icon.svg");
                -webkit-mask-image: url("../icons/info.icon.svg");
            }
        }
    }
    .tox-dialog__content-js {
        .tox-dialog__body {
            .tox-form__group {
                .tox-label {
                    font-size: var(--app-font-size-16);
                    font-weight: var(--app-form-label-font-weight);
                    color: var(--app-text-icon-primary-black);
                }
                .tox-custom-editor,
                .tox-listboxfield .tox-listbox--select,
                .tox-textarea-wrap,
                .tox-textarea,
                .tox-textfield {
                    font-family: var(--app-font-family);
                    border-radius: var(--bs-border-radius);
                }

                .tox-listbox__select-label {
                    font-size: var(--app-font-size-16);
                    font-family: var(--app-font-family);
                }

                .tox-custom-editor:focus-within,
                .tox-listboxfield .tox-listbox--select:focus,
                .tox-textarea-wrap:focus-within,
                .tox-textarea:focus,
                .tox-textfield:focus {
                    border-color: var(--app-primary-color);
                    box-shadow: var(--app-form-input-box-shadow);
                    outline: var(--app-form-input-outline-width-focus) solid
                        var(--app-primary-color);
                }
            }
        }
    }
}
.tox-collection__item {
    .tox-collection__item-label {
        font-size: var(--app-font-size-16) !important;
        font-family: var(--app-font-family) !important;
    }
    &--active {
        background-color: var(--app-primary-color-bg) !important;
    }
    &--enabled {
        .tox-collection__item-checkmark {
            all: unset;
            background-color: var(--app-black-color);
            mask-image: url("../icons/check.icon.svg");
            -webkit-mask-image: url("../icons/check.icon.svg");
        }
    }
}

.tox-dialog__footer {
    padding-bottom: 1.6rem !important;

    .tox-dialog__footer-end {
        .tox-button {
            font-family: var(--app-font-family);
            font-size: var(--app-font-size-16);
            font-weight: var(--app-button-font-weight);
            border-radius: var(--app-button-border-radius);
            border-color: var(--app-primary-color);
            color: var(--app-text-icon-primary-white);
            height: var(--app-button-height);
            padding: var(--app-button-padding);
            outline: var(--app-button-outline);
            outline-offset: var(--app-button-outline-offset);
            box-shadow: var(--app-button-box-shadow);
            background-color: var(--app-primary-color);

            &:hover:not(:disabled) {
                border-color: var(--app-primary-color-hover);
                background-color: var(--app-primary-color-hover);
            }
        }
        .tox-button--secondary {
            color: var(--app-text-icon-primary-black);
            border-color: var(--app-neutral-300);
            background-color: var(--app-text-icon-primary-white);

            &:hover:not(:disabled) {
                border-color: var(--app-neutral-800);
                background-color: var(--app-neutral-200);
            }
        }
    }
}
