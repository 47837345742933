.app-grid-status {
    display: flex;
    align-items: center;
    gap: var(--app-gap-3);
    padding: 0.4rem 1rem;
    border-radius: 10rem;
    height: 2.5rem;

    &.failed {
        color: var(--app-primary-color);
        background-color: var(--app-primary-color-bg);
    }

    &.paid {
        color: var(--app-success-color);
        background-color: var(--app-success-color-bg);
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;

        &.failed {
            background-color: var(--app-primary-color);
        }

        &.paid {
            background-color: var(--app-success-color);
        }
    }
}
