@import "../../../../assets/scss/mixins";

.app-alert-bar {
    display: flex;
    overflow: hidden;
    font-size: var(--app-font-size-14);
    padding: var(--app-alert-bar-padding);
    color: var(--app-text-icon-primary-black);
    border-radius: var(--app-alert-bar-border-radius);

    // size
    &--lg {
        padding: var(--app-alert-bar-padding-lg);
    }

    // variants
    &--info {
        background-color: var(--app-primary-color-bg);

        i {
            background-color: var(--app-primary-color);
        }
    }

    &--success {
        background-color: var(--app-success-color-bg);

        i {
            background-color: var(--app-success-color);
        }
    }

    &--warning {
        background-color: var(--app-warning-color-bg);

        i {
            background-color: var(--app-warning-color);
        }
    }

    &--danger {
        background-color: var(--app-danger-color-bg);

        i {
            background-color: var(--app-danger-color);
        }
    }

    // icon
    &--icon {
        flex-shrink: 0;
    }

    // title
    &--title {
        font-weight: var(--app-font-weight-semi-bold);

        @include text-overflow(3);
    }

    // description
    &--description {
        font-weight: var(--app-font-weight-light);

        @include text-overflow(5);
    }
}
