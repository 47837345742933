.app-inline-input {
    min-height: 0.6rem !important;
    padding: 0 0.375rem !important;

    &.type-area {
        height: auto;
        resize: none;
        overflow-y: scroll;
        font-size: var(--app-font-size-12) !important;

        &--xs {
            max-height: 6rem;
        }

        &--small {
            max-height: 8rem;
        }

        &--medium {
            max-height: 12rem;
        }
        &--large {
            max-height: 18rem;
        }
    }
    &--xs {
        font-size: var(--app-font-size-12) !important;
    }
    &--sm {
        font-size: var(--app-font-size-14) !important;
    }
    &--md {
        font-size: var(--app-font-size-20) !important;
    }
    &--lg {
        font-size: var(--app-font-size-24) !important;
    }
}
