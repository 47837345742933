@import "../../../../../assets/scss/mixins";
@import "../../../../../assets/scss/typography";

.map-dashboard-assigned-responsibilities {
    border-radius: 0.4rem;
    background-color: var(--app-text-icon-primary-white);
    padding: 2rem;
    overflow: hidden;
    min-height: 20rem;

    &--header {
        .title {
            @extend .font-weight--semi-bold;
            @extend .headline--200;
            color: var(--app-black-color);
        }
    }

    &--info {
        display: flex;
        flex-direction: column;
        gap: var(--app-gap-4);

        &--search-bar {
            .app-search-bar {
                width: 100%;

                &--icon {
                    @include absoluteAll(50%, 2%);
                }
            }
        }

        &--users {
            display: flex;
            flex-direction: column;
            gap: var(--app-gap-2);

            @include scroll-style($height: 0.5rem) {
                overflow-x: hidden;
                overflow-y: auto;
                max-height: 26rem;
            }

            .user-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: var(--app-text-icon-primary-white);

                &--avatar {
                    flex-basis: 25%;
                    padding: 0.4rem 0.8rem;
                    background-color: var(--map-dashboard-color-done);
                    transition: background-color 0.3s ease;

                    &.short {
                        background-color: var(--map-dashboard-color-short);
                    }

                    &.medium {
                        background-color: var(--map-dashboard-color-medium);
                    }

                    &.long {
                        background-color: var(--map-dashboard-color-long);
                    }
                }

                &--progress {
                    display: flex;
                    flex-grow: 1;
                    height: 100%;
                    background-color: var(--app-text-icon-primary-white);
                    border-radius: 0.4rem;

                    &--fill {
                        padding: 1.4rem 0.8rem;
                        background-color: var(--map-dashboard-color-done);
                        min-width: 3rem;
                        transition: background-color 0.3s ease;

                        &.short {
                            background-color: var(--map-dashboard-color-short);
                        }

                        &.medium {
                            background-color: var(--map-dashboard-color-medium);
                        }

                        &.long {
                            background-color: var(--map-dashboard-color-long);
                        }
                    }

                    .total {
                        @extend .paragraph--200;
                        @extend .font-weight--bold;
                        @include text-overflow(1);
                        text-align: end;
                    }
                }
            }
        }
    }
}
