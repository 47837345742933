@import "../../../../../assets/scss/typography";

.client-page {
    &--form {
        .user-title-container {
            display: flex;
            flex-direction: column;
            margin-top: 3rem;
            gap: var(--app-gap-3);

            .separator {
                padding: 0 2rem;
                border-top: 0.2rem dashed var(--app-neutral-300);
            }

            .title {
                @extend .headline--200;
                @extend .font-weight--bold;
            }
        }
    }
    .ag-center-cols-viewport {
        .ag-center-cols-container {
            margin-right: 0;
        }
    }
}
