.map-vote-checkbox {
    display: flex;
    align-items: center;
    gap: var(--app-gap-4);

    .label-container {
        display: flex;
        align-items: center;
        gap: var(--app-gap-3);

        .title {
            font-size: var(--app-font-size-13);
            color: var(--app-neutral-900);
        }
    }

    .form-check-input {
        width: 2.4rem;
        height: 2.4rem;
    }
}
