@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

// with BEM technique
.subscription-plan-cards {
    &__box {
        padding: 2.5rem 1.6rem 2rem;
        border-radius: 0.3rem;
        box-shadow: var(--app-box-shadow-200);
        background-color: var(--app-text-icon-primary-white);

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--app-gap-3);

        position: relative;
        height: fit-content;

        &--active {
            border: 0.2rem dashed var(--app-primary-color);
        }
    }

    &__current-plan {
        position: absolute;
        top: 0;
        right: 1.6rem;
        padding: 0.8rem;
        background-color: var(--app-primary-color-bg);
        @extend .label--200;
    }

    &__box-content,
    &__box-action {
        display: flex;
        flex-direction: column;
        gap: var(--app-gap-5);
    }

    &__box-action {
        overflow: hidden;
    }

    &__plans {
        display: flex;
        flex-direction: column;

        .title {
            @extend .headline--200;
            @extend .font-weight--bold;
        }

        .description {
            @extend .headline--400;
            @extend .font-weight--light;
            @include text-overflow(2);
        }
    }

    &__cost {
        display: flex;
        flex-direction: column;

        .title {
            @extend .headline--400;
            @extend .font-weight--light;
        }

        .cost-content {
            .cost-value {
                @extend .headline--200;
                @extend .font-weight--bold;
            }
            .cost-duration {
                @extend .headline--300;
                @extend .font-weight--bold;
            }
        }

        .description {
            @extend .paragraph--100;
            @extend .font-weight--light;
        }
    }

    &__seats {
        display: flex;

        .seats-info {
            display: flex;
            flex-direction: column;
            margin-right: 5rem;
            gap: var(--app-gap-2);

            .title {
                display: flex;
                align-items: center;
                gap: var(--app-gap-2);

                @extend .headline--300;
                @extend .font-weight--bold;
            }

            .description {
                @extend .paragraph--200;
                @extend .font-weight--light;
            }
        }

        .seats-input {
            width: 7rem;

            .seats-input-field {
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: auto;
                    -moz-appearance: auto;
                    appearance: auto;
                }
            }
        }
    }

    &__pay-cycle {
        // padding: 0 7rem;
        display: flex;
        justify-content: center;

        .pill {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: var(--app-gap-1);
            padding: 0.8rem 5rem;
            box-shadow: var(--app-box-shadow-100);
            cursor: pointer;
            height: 7rem;
            min-width: 17rem;
            transition: all 0.2s;
            background-color: var(--app-neutral-200);

            @media only screen and (max-width: 1200px) {
                min-width: unset;
            }

            &--monthly {
                margin-right: -2rem;

                border-top-left-radius: 7rem;
                border-bottom-left-radius: 7rem;
            }

            &--yearly {
                margin-left: -2rem;

                border-top-right-radius: 7rem;
                border-bottom-right-radius: 7rem;
            }

            &--active {
                border-radius: 7rem;
                box-shadow: var(--app-box-shadow-200);
                background-color: var(--app-text-icon-primary-white);
                position: relative;
                z-index: var(--app-z-index-normal);
            }

            &--disabled {
                cursor: not-allowed;
            }

            .title {
                @extend .paragraph--100;
                @extend .font-weight--bold;
                @include text-overflow(1);
            }

            .discount {
                @extend .paragraph--200;
                @extend .font-weight--bold;
                @include text-overflow(1);

                color: var(--app-success-color);
            }

            .description {
                @extend .paragraph--300;
                @extend .font-weight--light;
            }
        }
    }

    &__list {
        padding: 0;
        list-style: none;

        @include scroll-style($height: 0.5rem) {
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    &__list-item {
        display: flex;
        padding: 0.8rem 3.5rem;
        gap: var(--app-gap-3);
        align-items: center;

        &:nth-child(odd) {
            background-color: var(--app-neutral-200);
        }

        .icon {
            background-color: var(--app-success-color);
        }

        .title {
            @extend .paragraph--100;
            @extend .font-weight--light;
        }
    }
}
