@import "../../../../assets/scss/mixins";

.app-file-detail {
    height: 3.2rem;
    color: var(--app-text-icon-primary-black);
    border: 0.1rem solid var(--app-neutral-300);
    border-radius: 0.4rem;
    padding: 0.6rem 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--content {
        display: flex;
        align-items: center;

        .file-name {
            @include text-overflow(1);
        }

        .file-size {
            @include text-overflow(1);

            color: var(--app-text-icon-disabled-black);
        }
    }
}
