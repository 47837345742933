// with BEM technique
.subscription-billing-page {
    &__header {
    }

    &__date-picker {
        border: var(--bs-border-width) solid var(--app-neutral-300);
        border-radius: var(--bs-border-radius);
    }
}
