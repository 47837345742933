@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

.subscription-card-modal {
    &__content {
        display: flex;
        flex-direction: column;
        gap: var(--app-gap-4);

        .separator-line {
            border-bottom: 0.1rem dashed var(--app-text-icon-disabled-black);
        }
    }
}
