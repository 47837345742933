@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

// with BEM technique
.subscription-payment-info {
    display: flex;
    flex-direction: column;
    gap: var(--app-gap-4);
    margin-top: 0.8rem;

    &__content {
        display: flex;
        flex-direction: column;
        gap: var(--app-gap-3);

        &--description {
            .title {
                @extend .headline--300;
                @extend .font-weight--bold;
            }

            .condition {
                @extend .paragraph--300;
                @extend .font-weight--bold;
            }
        }

        &--method {
            .title {
                @extend .paragraph--100;
                @extend .font-weight--bold;
            }

            .content {
                display: flex;
                gap: var(--app-gap-3);
                align-items: center;

                .card-number {
                    @extend .paragraph--200;
                    @extend .font-weight--bold;
                    flex-shrink: 0;
                }

                .card-name {
                    @extend .paragraph--400;
                    @extend .font-weight--bold;
                    flex-shrink: 0;
                }

                .card-btn {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        &--billing-cycle {
            .title {
                @extend .paragraph--100;
                @extend .font-weight--bold;
            }

            .info {
                @extend .paragraph--200;
                @extend .font-weight--bold;
            }
        }

        &--renew {
            flex-direction: row;

            .title {
                @extend .paragraph--200;
                @extend .font-weight--bold;
                margin-right: 1.6rem;
            }
        }
    }
}
