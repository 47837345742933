@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

// with BEM technique
.subscription-pricing-page {
    &__header {
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(3, minmax(25rem, 45rem));
        grid-template-rows: 85vh;
        grid-column-gap: 2.4rem;
        justify-content: center;
        margin-top: 1rem;
    }
}
