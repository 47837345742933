.register-page {
    .terms {
        display: block;

        font-weight: var(--app-font-weight-light);
        font-size: var(--app-font-size-13);
        line-height: var(--app-font-size-24);
    }

    .form-check {
        display: flex;
        align-items: start;
    }

    .active-link {
        color: var(--app-primary-color);
        font-weight: var(--app-font-weight-semi-bold);
        cursor: pointer;
    }
}
