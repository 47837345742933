@import "../../../../../assets/scss/typography";

.app-checkbox {
    display: flex;
    align-items: center;
    gap: var(--app-gap-4);

    .form-check-input {
        width: 2.4rem;
        height: 2.4rem;
        border-color: var(--app-primary-color);

        &:checked {
            background-color: var(--app-primary-color);
            border-color: var(--app-primary-color);
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem var(--app-primary-color-bg);
        }
    }

    .form-check-label {
        color: var(--app-text-icon-primary-black) !important;
        @extend .paragraph--300;
        @extend .font-weight--bold;
    }
}
