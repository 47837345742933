// with BEM technique
.subscription-complete-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--app-gap-5);

    &__title {
        display: flex;
        align-items: center;
        gap: var(--app-gap-3);

        &--success {
            color: var(--app-success-color);

            i {
                background-color: var(--app-success-color);
            }
        }

        &--failure {
            color: var(--app-primary-color);

            i {
                background-color: var(--app-primary-color);
            }
        }
    }
}
