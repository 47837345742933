@import "../../../../../assets/scss/typography";

.map-progress {
    display: flex;
    flex-direction: column;

    &--title {
        @extend .paragraph--200;
        @extend .font-weight--bold;
    }

    &--path {
        padding: 0.7rem;
        display: inline-flex;
        background-color: var(--app-neutral-200);
        border-radius: 2rem;
        max-width: 22rem;
        width: 100%;
        .rating__input {
            display: none;
        }

        .rating__label {
            padding-right: 0.2rem;
            display: flex;
            .color-label {
                width: 1.1rem;
                height: 2rem;
                border-radius: 2rem;
                transition: all 0.2s ease-out;
            }
            &.neutral {
                .color-label {
                    background-color: var(--app-primary-color);
                }
            }
            &.orange {
                .color-label {
                    background-color: var(--app-warning-color);
                }
            }
            &.yellow {
                .color-label {
                    background-color: var(--app-yellow-color);
                }
            }
            &.green {
                .color-label {
                    background-color: var(--app-success-color-hover);
                }
            }
        }

        .rating__input:checked ~ .rating__label {
            .color-label {
                background-color: var(--app-text-icon-primary-white) !important;
            }
        }
    }
}
