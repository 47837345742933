@import "../../../AppForm/AppUploader/assets/scss/style";

.app-cropper {
    &--content {
        @extend .app-uploader--container;
    }

    &--action {
    }

    .app-zoom-slider--input {
        width: 14rem;
    }
}

.app-cropper-modal {
    .app-zoom-slider--input {
        width: 14rem;
    }
}
