@import "../../../../../assets/scss/typography";

.workspace-item {
    display: flex;
    flex-direction: column;
    border-radius: 0.4rem;
    padding: 1.6rem 0.8rem;
    box-shadow: var(--app-box-shadow-100);
    background-color: var(--app-text-icon-primary-white);
    min-height: 6.4rem;

    &--main {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &--content {
            display: flex;
            align-items: center;
            flex: 1;

            .title {
                @extend .headline--300;
                @extend .font-weight--bold;
            }
        }

        &--action {
            display: flex;
            align-items: center;

            .title {
                @extend .headline--300;
                @extend .font-weight--bold;
            }
        }
    }

    &--maps {
        padding: 1.6rem 0.8rem;

        &--form {
            .user-title-container {
                display: flex;
                flex-direction: column;
                margin-top: 3rem;
                gap: var(--app-gap-3);

                .separator {
                    padding: 0 2rem;
                    border-top: 0.2rem dashed var(--app-neutral-300);
                }

                .title {
                    @extend .headline--200;
                    @extend .font-weight--bold;
                }
            }
        }
    }
}
