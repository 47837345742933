@import "../../../../../assets/scss/mixins";
@import "../../../../../assets/scss/typography";

.map-pie-chart {
    position: relative;
    margin-top: -4rem;
    margin-bottom: -4rem;
    margin-right: -4rem;

    // chart tooltip design override
    .google-visualization-tooltip {
        path {
            fill: var(--app-neutral-1000);
        }
    }

    .chart-number {
        @include absoluteAll(50%, 50%);

        color: var(--app-black-color);
        pointer-events: none;

        @extend .font-weight--semi-bold;
        @extend .headline--100;
    }
}
