@import "../../../../../assets/scss/typography";

.app-map-detail-filter {
    .vote-color {
        &--container {
            background-color: var(--app-neutral-100);
            border-radius: 0.4rem;
            border: var(--map-card-border);
        }
    }
}
