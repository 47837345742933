@import "../../../../../assets/scss/mixins";
@import "../../../assets/scss/style";

.app-phone-number {
    --react-international-phone-border-radius: var(--bs-border-radius);
    --react-international-phone-border-color: var(--app-neutral-300);
    --react-international-phone-background-color: var(--bs-body-bg);
    --react-international-phone-text-color: var(--app-text-icon-primary-black);
    --react-international-phone-country-selector-background-color-hover: none;
    --react-international-phone-focused-dropdown-item-background-color: var(
        --app-primary-color-hover
    );
    --react-international-phone-selected-dropdown-item-background-color: var(
        --app-primary-color-bg
    );
    --react-international-phone-dropdown-item-dial-code-color: var(
        --app-text-icon-primary-black
    );

    &--input {
        @extend .form-control;
        margin-left: 0.2rem !important;
    }

    &--country-button {
        @extend .form-control;
    }

    &--country-dropdown {
        &:focus {
            outline: none;
        }
    }
}
