.app-portfolio-setting {
    .weight-levels {
        &--header {
            display: flex;
            justify-content: space-between;
            border-bottom: 0.1rem solid var(--app-neutral-1000);
            padding-bottom: 0.5rem;
        }

        &--content {
            display: flex;
            flex-direction: column;

            &--item {
                display: flex;
                align-items: center;
            }
        }
    }
}
