@keyframes progressBarAnimation {
    0% {
        background-color: var(--app-danger-color); // Initial color
    }

    25% {
        background-color: var(--app-warning-color); // Color at 25% progress
    }

    50% {
        background-color: var(--app-success-color); // Color at 50% progress
    }

    100% {
        background-color: var(--app-success-color); // Color at 100% progress
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
