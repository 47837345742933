@import "../../../../assets/scss/mixins";

.app-loader {
    width: var(--app-loader-height);
    height: var(--app-loader-height);
    animation: spin 1s linear infinite;
    z-index: var(--app-z-index-loader);

    &.overlay-loader {
        @include absoluteAll(50%, 48%);
    }

    // with react portal
    &.overlay-body {
        @extend .overlay-loader;

        left: 50%;
    }

    // size
    &--sm {
        width: var(--app-loader-height-sm);
        height: var(--app-loader-height-sm);
    }

    &--lg {
        width: var(--app-loader-height-lg);
        height: var(--app-loader-height-lg);
    }
}
