@import "../../../../assets/scss/mixins";

.app-not-found {
    &__top-image {
        @include default-image-style(
            url("../../../../assets/images/bg_auth_right.svg"),
            top center
        );

        height: 100%;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: var(--app-z-index-bg-image);
    }

    &__middle-image {
        @include default-image-style(
            url("../../../../assets/images/animals-404.svg"),
            center center,
            42.5vw
        );

        height: 100%;
        position: absolute;
        transform: translateY(50%);
        bottom: 50%;
        width: 100%;
        z-index: var(--app-z-index-bg-image);

        @media (width <= 1350px) {
            @include default-image-style(
                url("../../../../assets/images/animals-404.svg"),
                center center,
                45vw
            );
        }
    }
}
