// main typography
.headline {
    &--50 {
        font-size: var(--app-font-size-60);
    }

    &--100 {
        font-size: var(--app-font-size-36);
        line-height: var(--app-font-size-40);
    }

    &--200 {
        font-size: var(--app-font-size-24);
        line-height: var(--app-font-size-28);
    }

    &--300 {
        font-size: var(--app-font-size-20);
        line-height: var(--app-font-size-24);
    }

    &--400 {
        font-size: var(--app-font-size-16);
        line-height: var(--app-font-size-20);
    }
}

.paragraph {
    &--100 {
        font-size: var(--app-font-size-16);
        line-height: var(--app-font-size-24);
    }

    &--200 {
        font-size: var(--app-font-size-14);
        line-height: var(--app-font-size-20);
    }

    &--300 {
        font-size: var(--app-font-size-12);
        line-height: var(--app-font-size-14);
    }

    &--400 {
        font-size: var(--app-font-size-10);
        line-height: var(--app-font-size-14);
    }
}

.label {
    @extend .font-weight--bold;

    &--100 {
        font-size: var(--app-font-size-16);
        line-height: var(--app-font-size-16);

        @extend .font-weight--bold;
    }

    &--200 {
        font-size: var(--app-font-size-14);
        line-height: var(--app-font-size-16);

        @extend .font-weight--bold;
    }

    &--300 {
        font-size: var(--app-font-size-12);
        line-height: var(--app-font-size-12);

        @extend .font-weight--bold;
    }

    &--400 {
        font-size: var(--app-font-size-10);
        line-height: var(--app-font-size-16);

        @extend .font-weight--bold;
    }
}

.font-weight {
    &--light {
        font-weight: var(--app-font-weight-light);
    }

    &--semi-bold {
        font-weight: var(--app-font-weight-semi-bold);
    }

    &--bold {
        font-weight: var(--app-font-weight-bold);
    }
}

.text-secondary {
    color: var(--app-text-icon-secondary-black) !important;
}

.primary-color {
    color: var(--app-primary-color);
}
