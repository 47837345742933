@import "../../../../assets/scss/mixins";

.app-action-dropdown {
    cursor: pointer;

    &--content {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            a {
                display: flex;
                align-items: center;
                height: 4rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                text-decoration: none;
                color: var(--app-text-icon-primary-black);
                font-size: var(--app-font-size-14);
                gap: var(--app-gap-2);

                span {
                    @include text-overflow(1);
                }

                &:hover {
                    background-color: var(--app-primary-color-bg);
                }
            }
        }
    }

    i {
        background-color: var(--app-text-icon-primary-black);
    }
}

.popover-container {
    border: var(--app-action-drop-down-border);
    min-width: var(--app-action-drop-down-min-width);
    max-width: var(--app-action-drop-down-max-width);
    padding: var(--app-action-drop-down-padding);
    box-shadow: var(--app-box-shadow-400);
}
