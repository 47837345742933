@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

:root {
    --map-action-task-container-padding: 2rem;

    // --map-action-task-container-width: 25rem;
    --map-action-task-container-width: 34rem;
    --map-portfolio-container-padding: 2rem;
    --map-portfolio-card-height: 19rem;

    // --map-portfolio-card-width: 21rem; // (--map-action-task-container-width - 2*--map-portfolio-container-padding)
    --map-portfolio-card-width: 30rem; // (--map-action-task-container-width - 2*--map-portfolio-container-padding)
}

.map-portfolio-page {
    &--container {
        display: flex;
        flex-direction: column;
        gap: var(--app-gap-3);
        padding-top: 0.7rem;

        @include scroll-style() {
            overflow: auto;
        }

        &--separator {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0.1rem dashed var(--app-neutral-800);

            .text {
                position: absolute;
                padding: 0 3rem;
                // margin: 0 0 3rem 0;
                background-color: var(--app-neutral-200);

                @extend .headline--200;
                @extend .font-weight--light;
            }
        }

        &--action-tasks {
            display: flex;
            gap: var(--app-gap-5);

            .action-task-wrapper {
                display: flex;
                flex-direction: column;
                column-gap: var(--app-gap-5);
                row-gap: 5rem;

                .action-task-container {
                    display: flex;
                    flex-direction: column;
                    background-color: var(--app-text-icon-primary-white);
                    box-shadow: var(--app-box-shadow-100);
                    min-width: var(--map-action-task-container-width);
                    border-radius: 0.4rem 0.4rem 0 0;
                    // overflow: hidden;

                    &.short {
                        .portfolio-container--item--action {
                            background-image: var(--app-gradient-red-color);
                        }
                    }

                    &.medium {
                        .portfolio-container--item--action {
                            background-image: var(--app-gradient-yellow-color);
                        }
                    }

                    &.long {
                        .portfolio-container--item--action {
                            background-image: var(
                                --app-gradient-secondary-color
                            );
                        }
                    }

                    &--item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        height: 10rem;
                        background-image: var(--app-gradient-neutral-color);
                        padding: var(--map-action-task-container-padding)
                            var(--map-action-task-container-padding) 0
                            var(--map-action-task-container-padding);
                        border-radius: 0.4rem;

                        &--action {
                            display: flex;
                            flex: 1;
                            justify-content: space-between;
                            width: var(--map-portfolio-card-width);

                            .total-cards {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 3.2rem;
                                width: 3.2rem;
                                border-radius: 50%;
                                background-color: var(--app-neutral-800);

                                .title {
                                    @extend .paragraph--200;
                                    @extend .font-weight--bold;

                                    color: var(--app-text-icon-primary-white);

                                    @include text-overflow(1);
                                }
                            }
                        }

                        &--content {
                            display: flex;
                            flex: 1;
                            justify-content: space-between;
                            align-items: center;
                            width: var(--map-portfolio-card-width);

                            .title {
                                @extend .headline--300;
                                @extend .font-weight--semi-bold;
                                @include text-overflow(1);
                            }
                        }
                    }

                    .portfolio-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: var(--app-gap-3);
                        padding: var(--map-portfolio-container-padding);

                        &--item {
                            display: flex;
                            flex-direction: column;
                            min-height: var(--map-portfolio-card-height);
                            width: var(--map-portfolio-card-width);
                            box-shadow: var(--app-box-shadow-100);
                            background-color: var(
                                --app-text-icon-primary-white
                            );

                            &.active-item {
                                outline: 0.2rem solid var(--app-primary-color);
                            }

                            &.filter-item {
                                outline: 0.2rem solid var(--app-primary-color);
                            }

                            &.un-filter-item {
                                background-color: var(
                                    --map-card-disabled-color
                                );
                                opacity: 0.5;
                            }

                            &--action {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                height: 2.4rem;
                                min-height: 2.4rem;
                                padding: var(--map-card-action-padding);
                                background-image: var(
                                    --app-gradient-green-color
                                );
                            }

                            &--content {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                flex-grow: 1;
                                padding: var(--map-card-content-padding);
                                gap: var(--app-gap-3);
                                position: relative;

                                .detail {
                                    display: flex;
                                    flex-direction: column;
                                    gap: var(--app-gap-2);

                                    .title {
                                        @extend .label--300;
                                        @include text-overflow(2);
                                        line-height: var(--app-font-size-16);

                                        &--full-height {
                                            &--with-responsible {
                                                @include text-overflow(4);
                                            }
                                            @include text-overflow(5);
                                        }
                                    }

                                    .description {
                                        @extend .paragraph--300;
                                        @extend .font-weight--light;
                                        @include text-overflow(3);
                                    }
                                }

                                .info {
                                    display: flex;
                                    flex-direction: column;
                                    gap: var(--app-gap-2);

                                    .info-date-with-average {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        .due-date {
                                            padding-left: 1.2rem;
                                            display: flex;
                                            align-items: center;
                                            @extend .paragraph--300;
                                        }

                                        .average-valuation {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            height: 3.2rem;
                                            width: 3.2rem;
                                            border-radius: 50%;
                                            background-color: var(
                                                --app-warning-color
                                            );

                                            .title {
                                                @extend .paragraph--200;
                                                @extend .font-weight--bold;

                                                color: var(--app-neutral-800);

                                                @include text-overflow(1);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &--form {
            .valuation-slider-container {
                display: flex;
                flex-direction: column;
                gap: var(--app-gap-3);

                .rc-slider-rail {
                    background-color: var(--app-neutral-200);
                }

                .valuation-number {
                    display: flex;
                    justify-content: space-between;

                    &--separator {
                        rotate: 90deg;
                    }
                }
            }
        }
    }
}
