.app-progress-bar {
    &--progress {
        width: 100%;
        background-color: var(--app-neutral-200);
        border-radius: var(--app-progress-bar-border-radius);
        overflow: hidden;

        &--fill {
            width: 1%;
            height: var(--app-progress-bar-height);

            // background-color: var(--app-success-color);
            // animation: progressBarAnimation 0.1s ease-in-out forwards;
        }
    }

    &--details {
        font-size: var(--app-font-size-13);
        line-height: var(--app-font-size-16);
        display: flex;
        justify-content: space-between;
    }

    &--lg {
        .app-progress-bar {
            &--progress {
                &--fill {
                    height: var(--app-progress-bar-height--lg);
                }
            }

            &--details {
                font-size: var(--app-font-size-14);
                line-height: var(--app-font-size-20);
            }
        }
    }
}
