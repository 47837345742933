@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

:root {
    // dashboard color
    --map-dashboard-color-done: var(--app-success-color);
    --map-dashboard-color-short: var(--app-primary-color);
    --map-dashboard-color-medium: var(--app-warning-color);
    --map-dashboard-color-long: var(--app-secondary-color);
    --map-dashboard-color-theme-1: var(--app-danger-color);
    --map-dashboard-color-theme-2: #23966b;
    --map-dashboard-color-theme-3: #f6ec00;
    --map-dashboard-color-theme-4: #ffaa01;
    --map-dashboard-color-theme-5: #ff4013;
    --map-dashboard-color-theme-6: #1581e2;
}

.map-dashboard-page {
}
