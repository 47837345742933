@import "../../../../assets/scss/mixins";

.flag {
    width: var(--app-flag-width);
    height: var(--app-flag-height);
    display: block;

    @include default-image-style;

    &--disabled {
        background-color: var(--app-text-icon-disabled-black);
    }

    // language flag icons
    &--language {
        &--en {
            background-image: url("../../../../assets/icons/flags/language/en.svg");
        }

        &--fr {
            background-image: url("../../../../assets/icons/flags/language/fr.svg");
        }

        &--it {
            background-image: url("../../../../assets/icons/flags/language/it.svg");
        }

        &--de {
            background-image: url("../../../../assets/icons/flags/language/de.svg");
        }

        &--zh {
            background-image: url("../../../../assets/icons/flags/language/zh.svg");
        }

        &--ru {
            background-image: url("../../../../assets/icons/flags/language/ru.svg");
        }

        &--pt {
            background-image: url("../../../../assets/icons/flags/language/pt.svg");
        }
    }

    // country flag icons
    &--country {
        &--ad {
            background-image: url("../../../../assets/icons/flags/country/ad.svg");
        }

        &--ae {
            background-image: url("../../../../assets/icons/flags/country/ae.svg");
        }

        &--af {
            background-image: url("../../../../assets/icons/flags/country/af.svg");
        }

        &--ag {
            background-image: url("../../../../assets/icons/flags/country/ag.svg");
        }

        &--ai {
            background-image: url("../../../../assets/icons/flags/country/ai.svg");
        }

        &--al {
            background-image: url("../../../../assets/icons/flags/country/al.svg");
        }

        &--am {
            background-image: url("../../../../assets/icons/flags/country/am.svg");
        }

        &--ao {
            background-image: url("../../../../assets/icons/flags/country/ao.svg");
        }

        &--aq {
            background-image: url("../../../../assets/icons/flags/country/aq.svg");
        }

        &--ar {
            background-image: url("../../../../assets/icons/flags/country/ar.svg");
        }

        &--as {
            background-image: url("../../../../assets/icons/flags/country/as.svg");
        }

        &--at {
            background-image: url("../../../../assets/icons/flags/country/at.svg");
        }

        &--au {
            background-image: url("../../../../assets/icons/flags/country/au.svg");
        }

        &--aw {
            background-image: url("../../../../assets/icons/flags/country/aw.svg");
        }

        &--ax {
            background-image: url("../../../../assets/icons/flags/country/ax.svg");
        }

        &--az {
            background-image: url("../../../../assets/icons/flags/country/az.svg");
        }

        &--ba {
            background-image: url("../../../../assets/icons/flags/country/ba.svg");
        }

        &--bb {
            background-image: url("../../../../assets/icons/flags/country/bb.svg");
        }

        &--bd {
            background-image: url("../../../../assets/icons/flags/country/bd.svg");
        }

        &--be {
            background-image: url("../../../../assets/icons/flags/country/be.svg");
        }

        &--bf {
            background-image: url("../../../../assets/icons/flags/country/bf.svg");
        }

        &--bg {
            background-image: url("../../../../assets/icons/flags/country/bg.svg");
        }

        &--bh {
            background-image: url("../../../../assets/icons/flags/country/bh.svg");
        }

        &--bi {
            background-image: url("../../../../assets/icons/flags/country/bi.svg");
        }

        &--bj {
            background-image: url("../../../../assets/icons/flags/country/bj.svg");
        }

        &--bl {
            background-image: url("../../../../assets/icons/flags/country/bl.svg");
        }

        &--bm {
            background-image: url("../../../../assets/icons/flags/country/bm.svg");
        }

        &--bn {
            background-image: url("../../../../assets/icons/flags/country/bn.svg");
        }

        &--bo {
            background-image: url("../../../../assets/icons/flags/country/bo.svg");
        }

        &--bq {
            background-image: url("../../../../assets/icons/flags/country/bq.svg");
        }

        &--br {
            background-image: url("../../../../assets/icons/flags/country/br.svg");
        }

        &--bs {
            background-image: url("../../../../assets/icons/flags/country/bs.svg");
        }

        &--bt {
            background-image: url("../../../../assets/icons/flags/country/bt.svg");
        }

        &--bv {
            background-image: url("../../../../assets/icons/flags/country/bv.svg");
        }

        &--bw {
            background-image: url("../../../../assets/icons/flags/country/bw.svg");
        }

        &--by {
            background-image: url("../../../../assets/icons/flags/country/by.svg");
        }

        &--bz {
            background-image: url("../../../../assets/icons/flags/country/bz.svg");
        }

        &--ca {
            background-image: url("../../../../assets/icons/flags/country/ca.svg");
        }

        &--cc {
            background-image: url("../../../../assets/icons/flags/country/cc.svg");
        }

        &--cd {
            background-image: url("../../../../assets/icons/flags/country/cd.svg");
        }

        &--cf {
            background-image: url("../../../../assets/icons/flags/country/cf.svg");
        }

        &--cg {
            background-image: url("../../../../assets/icons/flags/country/cg.svg");
        }

        &--ch {
            background-image: url("../../../../assets/icons/flags/country/ch.svg");
        }

        &--ci {
            background-image: url("../../../../assets/icons/flags/country/ci.svg");
        }

        &--ck {
            background-image: url("../../../../assets/icons/flags/country/ck.svg");
        }

        &--cl {
            background-image: url("../../../../assets/icons/flags/country/cl.svg");
        }

        &--cm {
            background-image: url("../../../../assets/icons/flags/country/cm.svg");
        }

        &--cn {
            background-image: url("../../../../assets/icons/flags/country/cn.svg");
        }

        &--co {
            background-image: url("../../../../assets/icons/flags/country/co.svg");
        }

        &--cr {
            background-image: url("../../../../assets/icons/flags/country/cr.svg");
        }

        &--cu {
            background-image: url("../../../../assets/icons/flags/country/cu.svg");
        }

        &--cv {
            background-image: url("../../../../assets/icons/flags/country/cv.svg");
        }

        &--cw {
            background-image: url("../../../../assets/icons/flags/country/cw.svg");
        }

        &--cx {
            background-image: url("../../../../assets/icons/flags/country/cx.svg");
        }

        &--cy {
            background-image: url("../../../../assets/icons/flags/country/cy.svg");
        }

        &--cz {
            background-image: url("../../../../assets/icons/flags/country/cz.svg");
        }

        &--de {
            background-image: url("../../../../assets/icons/flags/country/de.svg");
        }

        &--dj {
            background-image: url("../../../../assets/icons/flags/country/dj.svg");
        }

        &--dk {
            background-image: url("../../../../assets/icons/flags/country/dk.svg");
        }

        &--dm {
            background-image: url("../../../../assets/icons/flags/country/dm.svg");
        }

        &--do {
            background-image: url("../../../../assets/icons/flags/country/do.svg");
        }

        &--dz {
            background-image: url("../../../../assets/icons/flags/country/dz.svg");
        }

        &--ec {
            background-image: url("../../../../assets/icons/flags/country/ec.svg");
        }

        &--ee {
            background-image: url("../../../../assets/icons/flags/country/ee.svg");
        }

        &--eg {
            background-image: url("../../../../assets/icons/flags/country/eg.svg");
        }

        &--eh {
            background-image: url("../../../../assets/icons/flags/country/eh.svg");
        }

        &--er {
            background-image: url("../../../../assets/icons/flags/country/er.svg");
        }

        &--es {
            background-image: url("../../../../assets/icons/flags/country/es.svg");
        }

        &--et {
            background-image: url("../../../../assets/icons/flags/country/et.svg");
        }

        &--fi {
            background-image: url("../../../../assets/icons/flags/country/fi.svg");
        }

        &--fj {
            background-image: url("../../../../assets/icons/flags/country/fj.svg");
        }

        &--fk {
            background-image: url("../../../../assets/icons/flags/country/fk.svg");
        }

        &--fm {
            background-image: url("../../../../assets/icons/flags/country/fm.svg");
        }

        &--fo {
            background-image: url("../../../../assets/icons/flags/country/fo.svg");
        }

        &--fr {
            background-image: url("../../../../assets/icons/flags/country/fr.svg");
        }

        &--ga {
            background-image: url("../../../../assets/icons/flags/country/ga.svg");
        }

        &--gb {
            background-image: url("../../../../assets/icons/flags/country/gb.svg");
        }

        &--gd {
            background-image: url("../../../../assets/icons/flags/country/gd.svg");
        }

        &--ge {
            background-image: url("../../../../assets/icons/flags/country/ge.svg");
        }

        &--gf {
            background-image: url("../../../../assets/icons/flags/country/gf.svg");
        }

        &--gg {
            background-image: url("../../../../assets/icons/flags/country/gg.svg");
        }

        &--gh {
            background-image: url("../../../../assets/icons/flags/country/gh.svg");
        }

        &--gi {
            background-image: url("../../../../assets/icons/flags/country/gi.svg");
        }

        &--gl {
            background-image: url("../../../../assets/icons/flags/country/gl.svg");
        }

        &--gm {
            background-image: url("../../../../assets/icons/flags/country/gm.svg");
        }

        &--gn {
            background-image: url("../../../../assets/icons/flags/country/gn.svg");
        }

        &--gp {
            background-image: url("../../../../assets/icons/flags/country/gp.svg");
        }

        &--gq {
            background-image: url("../../../../assets/icons/flags/country/gq.svg");
        }

        &--gr {
            background-image: url("../../../../assets/icons/flags/country/gr.svg");
        }

        &--gs {
            background-image: url("../../../../assets/icons/flags/country/gs.svg");
        }

        &--gt {
            background-image: url("../../../../assets/icons/flags/country/gt.svg");
        }

        &--gu {
            background-image: url("../../../../assets/icons/flags/country/gu.svg");
        }

        &--gw {
            background-image: url("../../../../assets/icons/flags/country/gw.svg");
        }

        &--gy {
            background-image: url("../../../../assets/icons/flags/country/gy.svg");
        }

        &--hk {
            background-image: url("../../../../assets/icons/flags/country/hk.svg");
        }

        &--hm {
            background-image: url("../../../../assets/icons/flags/country/hm.svg");
        }

        &--hn {
            background-image: url("../../../../assets/icons/flags/country/hn.svg");
        }

        &--hr {
            background-image: url("../../../../assets/icons/flags/country/hr.svg");
        }

        &--ht {
            background-image: url("../../../../assets/icons/flags/country/ht.svg");
        }

        &--hu {
            background-image: url("../../../../assets/icons/flags/country/hu.svg");
        }

        &--id {
            background-image: url("../../../../assets/icons/flags/country/id.svg");
        }

        &--ie {
            background-image: url("../../../../assets/icons/flags/country/ie.svg");
        }

        &--il {
            background-image: url("../../../../assets/icons/flags/country/il.svg");
        }

        &--im {
            background-image: url("../../../../assets/icons/flags/country/im.svg");
        }

        &--in {
            background-image: url("../../../../assets/icons/flags/country/in.svg");
        }

        &--io {
            background-image: url("../../../../assets/icons/flags/country/io.svg");
        }

        &--iq {
            background-image: url("../../../../assets/icons/flags/country/iq.svg");
        }

        &--ir {
            background-image: url("../../../../assets/icons/flags/country/ir.svg");
        }

        &--is {
            background-image: url("../../../../assets/icons/flags/country/is.svg");
        }

        &--it {
            background-image: url("../../../../assets/icons/flags/country/it.svg");
        }

        &--je {
            background-image: url("../../../../assets/icons/flags/country/je.svg");
        }

        &--jm {
            background-image: url("../../../../assets/icons/flags/country/jm.svg");
        }

        &--jo {
            background-image: url("../../../../assets/icons/flags/country/jo.svg");
        }

        &--jp {
            background-image: url("../../../../assets/icons/flags/country/jp.svg");
        }

        &--ke {
            background-image: url("../../../../assets/icons/flags/country/ke.svg");
        }

        &--kg {
            background-image: url("../../../../assets/icons/flags/country/kg.svg");
        }

        &--kh {
            background-image: url("../../../../assets/icons/flags/country/kh.svg");
        }

        &--ki {
            background-image: url("../../../../assets/icons/flags/country/ki.svg");
        }

        &--km {
            background-image: url("../../../../assets/icons/flags/country/km.svg");
        }

        &--kn {
            background-image: url("../../../../assets/icons/flags/country/kn.svg");
        }

        &--kp {
            background-image: url("../../../../assets/icons/flags/country/kp.svg");
        }

        &--kr {
            background-image: url("../../../../assets/icons/flags/country/kr.svg");
        }

        &--kw {
            background-image: url("../../../../assets/icons/flags/country/kw.svg");
        }

        &--ky {
            background-image: url("../../../../assets/icons/flags/country/ky.svg");
        }

        &--kz {
            background-image: url("../../../../assets/icons/flags/country/kz.svg");
        }

        &--la {
            background-image: url("../../../../assets/icons/flags/country/la.svg");
        }

        &--lb {
            background-image: url("../../../../assets/icons/flags/country/lb.svg");
        }

        &--lc {
            background-image: url("../../../../assets/icons/flags/country/lc.svg");
        }

        &--li {
            background-image: url("../../../../assets/icons/flags/country/li.svg");
        }

        &--lk {
            background-image: url("../../../../assets/icons/flags/country/lk.svg");
        }

        &--lr {
            background-image: url("../../../../assets/icons/flags/country/lr.svg");
        }

        &--ls {
            background-image: url("../../../../assets/icons/flags/country/ls.svg");
        }

        &--lt {
            background-image: url("../../../../assets/icons/flags/country/lt.svg");
        }

        &--lu {
            background-image: url("../../../../assets/icons/flags/country/lu.svg");
        }

        &--lv {
            background-image: url("../../../../assets/icons/flags/country/lv.svg");
        }

        &--ly {
            background-image: url("../../../../assets/icons/flags/country/ly.svg");
        }

        &--ma {
            background-image: url("../../../../assets/icons/flags/country/ma.svg");
        }

        &--mc {
            background-image: url("../../../../assets/icons/flags/country/mc.svg");
        }

        &--md {
            background-image: url("../../../../assets/icons/flags/country/md.svg");
        }

        &--me {
            background-image: url("../../../../assets/icons/flags/country/me.svg");
        }

        &--mf {
            background-image: url("../../../../assets/icons/flags/country/mf.svg");
        }

        &--mg {
            background-image: url("../../../../assets/icons/flags/country/mg.svg");
        }

        &--mh {
            background-image: url("../../../../assets/icons/flags/country/mh.svg");
        }

        &--mk {
            background-image: url("../../../../assets/icons/flags/country/mk.svg");
        }

        &--ml {
            background-image: url("../../../../assets/icons/flags/country/ml.svg");
        }

        &--mm {
            background-image: url("../../../../assets/icons/flags/country/mm.svg");
        }

        &--mn {
            background-image: url("../../../../assets/icons/flags/country/mn.svg");
        }

        &--mo {
            background-image: url("../../../../assets/icons/flags/country/mo.svg");
        }

        &--mp {
            background-image: url("../../../../assets/icons/flags/country/mp.svg");
        }

        &--mq {
            background-image: url("../../../../assets/icons/flags/country/mq.svg");
        }

        &--mr {
            background-image: url("../../../../assets/icons/flags/country/mr.svg");
        }

        &--ms {
            background-image: url("../../../../assets/icons/flags/country/ms.svg");
        }

        &--mt {
            background-image: url("../../../../assets/icons/flags/country/mt.svg");
        }

        &--mu {
            background-image: url("../../../../assets/icons/flags/country/mu.svg");
        }

        &--mv {
            background-image: url("../../../../assets/icons/flags/country/mv.svg");
        }

        &--mw {
            background-image: url("../../../../assets/icons/flags/country/mw.svg");
        }

        &--mx {
            background-image: url("../../../../assets/icons/flags/country/mx.svg");
        }

        &--my {
            background-image: url("../../../../assets/icons/flags/country/my.svg");
        }

        &--mz {
            background-image: url("../../../../assets/icons/flags/country/mz.svg");
        }

        &--na {
            background-image: url("../../../../assets/icons/flags/country/na.svg");
        }

        &--nc {
            background-image: url("../../../../assets/icons/flags/country/nc.svg");
        }

        &--ne {
            background-image: url("../../../../assets/icons/flags/country/ne.svg");
        }

        &--nf {
            background-image: url("../../../../assets/icons/flags/country/nf.svg");
        }

        &--ng {
            background-image: url("../../../../assets/icons/flags/country/ng.svg");
        }

        &--ni {
            background-image: url("../../../../assets/icons/flags/country/ni.svg");
        }

        &--nl {
            background-image: url("../../../../assets/icons/flags/country/nl.svg");
        }

        &--no {
            background-image: url("../../../../assets/icons/flags/country/no.svg");
        }

        &--np {
            background-image: url("../../../../assets/icons/flags/country/np.svg");
        }

        &--nr {
            background-image: url("../../../../assets/icons/flags/country/nr.svg");
        }

        &--nu {
            background-image: url("../../../../assets/icons/flags/country/nu.svg");
        }

        &--nz {
            background-image: url("../../../../assets/icons/flags/country/nz.svg");
        }

        &--om {
            background-image: url("../../../../assets/icons/flags/country/om.svg");
        }

        &--pa {
            background-image: url("../../../../assets/icons/flags/country/pa.svg");
        }

        &--pe {
            background-image: url("../../../../assets/icons/flags/country/pe.svg");
        }

        &--pf {
            background-image: url("../../../../assets/icons/flags/country/pf.svg");
        }

        &--pg {
            background-image: url("../../../../assets/icons/flags/country/pg.svg");
        }

        &--ph {
            background-image: url("../../../../assets/icons/flags/country/ph.svg");
        }

        &--pk {
            background-image: url("../../../../assets/icons/flags/country/pk.svg");
        }

        &--pl {
            background-image: url("../../../../assets/icons/flags/country/pl.svg");
        }

        &--pm {
            background-image: url("../../../../assets/icons/flags/country/pm.svg");
        }

        &--pn {
            background-image: url("../../../../assets/icons/flags/country/pn.svg");
        }

        &--pr {
            background-image: url("../../../../assets/icons/flags/country/pr.svg");
        }

        &--ps {
            background-image: url("../../../../assets/icons/flags/country/ps.svg");
        }

        &--pt {
            background-image: url("../../../../assets/icons/flags/country/pt.svg");
        }

        &--pw {
            background-image: url("../../../../assets/icons/flags/country/pw.svg");
        }

        &--py {
            background-image: url("../../../../assets/icons/flags/country/py.svg");
        }

        &--qa {
            background-image: url("../../../../assets/icons/flags/country/qa.svg");
        }

        &--re {
            background-image: url("../../../../assets/icons/flags/country/re.svg");
        }

        &--ro {
            background-image: url("../../../../assets/icons/flags/country/ro.svg");
        }

        &--rs {
            background-image: url("../../../../assets/icons/flags/country/rs.svg");
        }

        &--ru {
            background-image: url("../../../../assets/icons/flags/country/ru.svg");
        }

        &--rw {
            background-image: url("../../../../assets/icons/flags/country/rw.svg");
        }

        &--sa {
            background-image: url("../../../../assets/icons/flags/country/sa.svg");
        }

        &--sb {
            background-image: url("../../../../assets/icons/flags/country/sb.svg");
        }

        &--sc {
            background-image: url("../../../../assets/icons/flags/country/sc.svg");
        }

        &--sd {
            background-image: url("../../../../assets/icons/flags/country/sd.svg");
        }

        &--se {
            background-image: url("../../../../assets/icons/flags/country/se.svg");
        }

        &--sg {
            background-image: url("../../../../assets/icons/flags/country/sg.svg");
        }

        &--sh {
            background-image: url("../../../../assets/icons/flags/country/sh.svg");
        }

        &--si {
            background-image: url("../../../../assets/icons/flags/country/si.svg");
        }

        &--sj {
            background-image: url("../../../../assets/icons/flags/country/sj.svg");
        }

        &--sk {
            background-image: url("../../../../assets/icons/flags/country/sk.svg");
        }

        &--sl {
            background-image: url("../../../../assets/icons/flags/country/sl.svg");
        }

        &--sm {
            background-image: url("../../../../assets/icons/flags/country/sm.svg");
        }

        &--sn {
            background-image: url("../../../../assets/icons/flags/country/sn.svg");
        }

        &--so {
            background-image: url("../../../../assets/icons/flags/country/so.svg");
        }

        &--sr {
            background-image: url("../../../../assets/icons/flags/country/sr.svg");
        }

        &--ss {
            background-image: url("../../../../assets/icons/flags/country/ss.svg");
        }

        &--st {
            background-image: url("../../../../assets/icons/flags/country/st.svg");
        }

        &--sv {
            background-image: url("../../../../assets/icons/flags/country/sv.svg");
        }

        &--sx {
            background-image: url("../../../../assets/icons/flags/country/sx.svg");
        }

        &--sy {
            background-image: url("../../../../assets/icons/flags/country/sy.svg");
        }

        &--sz {
            background-image: url("../../../../assets/icons/flags/country/sz.svg");
        }

        &--tc {
            background-image: url("../../../../assets/icons/flags/country/tc.svg");
        }

        &--td {
            background-image: url("../../../../assets/icons/flags/country/td.svg");
        }

        &--tf {
            background-image: url("../../../../assets/icons/flags/country/tf.svg");
        }

        &--tg {
            background-image: url("../../../../assets/icons/flags/country/tg.svg");
        }

        &--th {
            background-image: url("../../../../assets/icons/flags/country/th.svg");
        }

        &--tj {
            background-image: url("../../../../assets/icons/flags/country/tj.svg");
        }

        &--tk {
            background-image: url("../../../../assets/icons/flags/country/tk.svg");
        }

        &--tl {
            background-image: url("../../../../assets/icons/flags/country/tl.svg");
        }

        &--tm {
            background-image: url("../../../../assets/icons/flags/country/tm.svg");
        }

        &--tn {
            background-image: url("../../../../assets/icons/flags/country/tn.svg");
        }

        &--to {
            background-image: url("../../../../assets/icons/flags/country/to.svg");
        }

        &--tr {
            background-image: url("../../../../assets/icons/flags/country/tr.svg");
        }

        &--tt {
            background-image: url("../../../../assets/icons/flags/country/tt.svg");
        }

        &--tv {
            background-image: url("../../../../assets/icons/flags/country/tv.svg");
        }

        &--tw {
            background-image: url("../../../../assets/icons/flags/country/tw.svg");
        }

        &--tz {
            background-image: url("../../../../assets/icons/flags/country/tz.svg");
        }

        &--ua {
            background-image: url("../../../../assets/icons/flags/country/ua.svg");
        }

        &--ug {
            background-image: url("../../../../assets/icons/flags/country/ug.svg");
        }

        &--um {
            background-image: url("../../../../assets/icons/flags/country/um.svg");
        }

        &--us {
            background-image: url("../../../../assets/icons/flags/country/us.svg");
        }

        &--uy {
            background-image: url("../../../../assets/icons/flags/country/uy.svg");
        }

        &--uz {
            background-image: url("../../../../assets/icons/flags/country/uz.svg");
        }

        &--va {
            background-image: url("../../../../assets/icons/flags/country/va.svg");
        }

        &--vc {
            background-image: url("../../../../assets/icons/flags/country/vc.svg");
        }

        &--ve {
            background-image: url("../../../../assets/icons/flags/country/ve.svg");
        }

        &--vg {
            background-image: url("../../../../assets/icons/flags/country/vg.svg");
        }

        &--vi {
            background-image: url("../../../../assets/icons/flags/country/vi.svg");
        }

        &--vn {
            background-image: url("../../../../assets/icons/flags/country/vn.svg");
        }

        &--vu {
            background-image: url("../../../../assets/icons/flags/country/vu.svg");
        }

        &--wf {
            background-image: url("../../../../assets/icons/flags/country/wf.svg");
        }

        &--ws {
            background-image: url("../../../../assets/icons/flags/country/ws.svg");
        }

        &--ye {
            background-image: url("../../../../assets/icons/flags/country/ye.svg");
        }

        &--yt {
            background-image: url("../../../../assets/icons/flags/country/yt.svg");
        }

        &--za {
            background-image: url("../../../../assets/icons/flags/country/za.svg");
        }

        &--zm {
            background-image: url("../../../../assets/icons/flags/country/zm.svg");
        }

        &--zw {
            background-image: url("../../../../assets/icons/flags/country/zw.svg");
        }

        &--ac {
            background-image: url("../../../../assets/icons/flags/country/ac.svg");
        }

        &--cp {
            background-image: url("../../../../assets/icons/flags/country/cp.svg");
        }

        &--dg {
            background-image: url("../../../../assets/icons/flags/country/dg.svg");
        }

        &--ea {
            background-image: url("../../../../assets/icons/flags/country/ea.svg");
        }

        &--es-ct {
            background-image: url("../../../../assets/icons/flags/country/es-ct.svg");
        }

        &--es-ga {
            background-image: url("../../../../assets/icons/flags/country/es-ga.svg");
        }

        &--eu {
            background-image: url("../../../../assets/icons/flags/country/eu.svg");
        }

        &--gb-eng {
            background-image: url("../../../../assets/icons/flags/country/gb-eng.svg");
        }

        &--gb-nir {
            background-image: url("../../../../assets/icons/flags/country/gb-nir.svg");
        }

        &--gb-sct {
            background-image: url("../../../../assets/icons/flags/country/gb-sct.svg");
        }

        &--gb-wls {
            background-image: url("../../../../assets/icons/flags/country/gb-wls.svg");
        }

        &--ic {
            background-image: url("../../../../assets/icons/flags/country/ic.svg");
        }

        &--ta {
            background-image: url("../../../../assets/icons/flags/country/ta.svg");
        }

        &--un {
            background-image: url("../../../../assets/icons/flags/country/un.svg");
        }

        &--xk {
            background-image: url("../../../../assets/icons/flags/country/xk.svg");
        }

        &--xx {
            background-image: url("../../../../assets/icons/flags/country/xx.svg");
        }
    }
}
