@mixin text-overflow($num) {
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @content;
}

@mixin scroll-style(
    $width: var(--app-scrollbar-width),
    $height: var(--app-scrollbar-height)
) {
    @content;

    // scrollbar-color: var(--app-secondary-color) var(--app-secondary-color-bg);
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    // overscroll-behavior: contain;
    scroll-behavior: auto;

    &::-webkit-scrollbar {
        @if $width != 0 {
            width: $width;
        }

        @if $height != 0 {
            height: $height;
        }
    }

    &::-webkit-scrollbar-track {
        // background: var(--app-secondary-color-bg);
        // border-radius: var(--app-scrollbar-border-radius);
        background: transparent;
        border-radius: transparent;
    }

    &::-webkit-scrollbar-thumb {
        // background: var(--app-secondary-color);
        // border-radius: var(--app-scrollbar-border-radius);
        background: transparent;
        border-radius: transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
        opacity: 0.5;
    }
}

@mixin grid-gap-md-flex($gap, $justify-content: start) {
    display: grid;
    grid-gap: $gap;

    // @include media-breakpoint-up(md)
    @media (width >= 768px) {
        display: grid;
        justify-content: $justify-content;
    }
}

@mixin default-image-style($url: null, $position: center, $size: cover) {
    @if $url {
        background-image: $url;
    }

    background-position: $position;
    background-size: $size;
    background-repeat: no-repeat;
}

@mixin default-mask-style($url: null, $position: center, $size: contain) {
    @if $url {
        mask-image: $url;
        -webkit-mask-image: $url;
    }

    mask-position: $position;
    mask-size: $size;
    mask-repeat: no-repeat;
}

@mixin absolute($top: 50%, $left: 50%) {
    position: absolute;
    top: $top;
    left: $left;
    transform: translate(-50%, -50%);
}

@mixin absoluteAll($top: null, $left: null, $right: null, $bottom: null) {
    position: absolute;

    @if $top {
        top: $top;
    }

    @if $left {
        left: $left;
    }

    @if $right {
        right: $right;
    }

    @if $bottom {
        bottom: $bottom;
    }

    transform: translate(-50%, -50%);
}
