@import "../../../../../assets/scss/mixins";
@import "../../../../../assets/scss/typography";

.budget-card {
    border-radius: 0.4rem;
    background-color: var(--app-text-icon-primary-white);
    padding: 2rem;
    overflow: hidden;
    min-height: 20rem;

    &--header {
        .title {
            @extend .font-weight--semi-bold;
            @extend .headline--200;
            color: var(--app-black-color);
        }
    }

    &--info {
        display: flex;
        flex-direction: column;
        gap: var(--app-gap-4);

        .title {
            @extend .font-weight--bold;
            @extend .paragraph--200;
            color: var(--app-black-color);
            margin-left: 0.5rem;
        }

        &--used-estimated-section {
            display: flex;
            flex-wrap: wrap;
            gap: var(--app-gap-5);

            .item {
                display: flex;
                flex-direction: column;
                gap: var(--app-gap-2);

                .pill {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 3.2rem;
                    border-radius: 10rem;
                    padding: 0.8rem 1.6rem;
                    color: var(--app-text-icon-primary-white);
                    background-color: var(--app-secondary-color);

                    .count {
                        @extend .label--200;
                    }
                }
            }
        }

        &--available-section {
            display: flex;
            flex-direction: column;
            gap: var(--app-gap-2);
        }
    }
}
