@import "../../../../assets/scss/mixins";

.app-page-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--app-section-padding);

    &--left {
        display: flex;
        flex-direction: column;
    }

    &--center {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    &--right {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
}
