@import "../../../../assets/scss/mixins";
.app-tabs {
    display: flex;
    align-items: center;

    &--tab {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: var(--app-font-weight-semi-bold);
        font-size: var(--app-font-size-16);
        line-height: var(--app-font-size-16);
        color: var(--app-neutral-600);
        // background-color: var(--app-neutral-100);
        cursor: pointer;
        gap: var(--app-gap-3);
        height: 4rem;
        padding: 0 2rem;
        padding-bottom: 0.2rem;
        border-right: 0.1rem solid var(--app-neutral-300);

        &:last-child {
            border-right: none;
        }

        &::before {
            content: "";
            @include absoluteAll(null, 50%, 50%, 0.1rem);
            height: 0.3rem;
            transform: inherit;
            background-color: transparent;
            transition: all 0.2s ease-out;
        }

        .tab-icon {
            opacity: 0.7;
        }

        &.active {
            &::before {
                left: 0.75rem;
                right: 0;
                height: 100%;
                width: calc(100% - 1.75rem);
                background-color: var(--app-neutral-100);
                border-bottom: 0.3rem solid var(--app-primary-color);
            }

            color: var(--app-text-icon-primary-black);

            .tab-icon {
                opacity: 1;
            }
            .tab-text {
                z-index: var(--app-z-index-normal);
            }
        }
    }
}
