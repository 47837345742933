@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/typography";

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: var(--app-button-font-weight);
    border-radius: var(--app-button-border-radius);
    font-size: var(--app-font-size-16);
    color: var(--app-text-icon-primary-white);
    height: var(--app-button-height);
    padding: var(--app-button-padding);
    outline: var(--app-button-outline);
    outline-offset: var(--app-button-outline-offset);
    box-shadow: var(--app-button-box-shadow);

    .btn-text {
        @include text-overflow(1);
    }

    .btn-badge {
        @include absoluteAll(0, null, -1.4rem, null);

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--app-danger-color);
        border-radius: 50%;
        height: 1.6rem;
        width: 1.6rem;

        &--label {
            color: var(--app-text-icon-primary-white);

            @extend .label--300;
            @include text-overflow(1);
        }
    }

    // bootstrap class
    &.btn-sm {
        height: var(--app-button-height-sm);
        padding: var(--app-button-padding-sm);
    }

    // bootstrap class

    &.btn-square {
        height: var(--app-button-icon-height);
        width: var(--app-button-icon-height);
        padding: var(--app-button-icon-padding);
        display: flex;
        justify-content: center;
        align-items: center;

        &.btn-sm {
            height: var(--app-button-icon-height-sm);
            width: var(--app-button-icon-height-sm);
            padding: var(--app-button-icon-padding-sm);
        }
    }

    &.btn-circle {
        @extend .btn-square;

        border-radius: 50%;
        padding: var(--app-button-icon-padding-circle);

        &.btn-sm {
            padding: var(--app-button-icon-padding-circle);
        }
    }

    &.btn-primary {
        color: var(--app-text-icon-primary-white);
        border-color: var(--app-primary-color);
        background-color: var(--app-primary-color);

        i {
            background-color: var(--app-text-icon-primary-white);
        }

        &:hover {
            color: var(--app-text-icon-primary-white);
            border-color: var(--app-primary-color-hover);
            background-color: var(--app-primary-color-hover);
        }

        &:active {
            color: var(--app-text-icon-primary-white);
            border-color: var(--app-primary-color-pressed);
            background-color: var(--app-primary-color-pressed);
        }

        &:disabled {
            color: var(--app-text-icon-disabled-black);
            border-color: var(--app-neutral-200);
            background-color: var(--app-neutral-200);
            opacity: var(--app-button-opacity-disabled);

            i {
                background-color: var(--app-text-icon-disabled-black);
            }
        }

        &:focus-visible {
            color: var(--app-text-icon-primary-white);
            border-color: var(--app-primary-color-hover);
            background-color: var(--app-primary-color-hover);
            outline: var(--app-button-outline-width-focus) solid
                var(--app-primary-color-hover);
        }
    }

    &.btn-secondary {
        color: var(--app-text-icon-primary-black);
        border-color: var(--app-neutral-300);
        background-color: var(--app-text-icon-primary-white);

        i {
            background-color: var(--app-text-icon-primary-black);
        }

        &:hover {
            color: var(--app-text-icon-primary-black);
            border-color: var(--app-neutral-800);
            background-color: var(--app-neutral-200);
        }

        &:active {
            color: var(--app-text-icon-primary-black);
            border-color: var(--app-neutral-800);
            background-color: var(--app-neutral-300);
        }

        &:disabled {
            color: var(--app-text-icon-disabled-black);
            border-color: var(--app-neutral-300);
            background-color: var(--app-neutral-200);
            opacity: var(--app-button-opacity-disabled);

            i {
                background-color: var(--app-text-icon-disabled-black);
            }
        }

        &:focus-visible {
            color: var(--app-text-icon-primary-black);
            border-color: var(--app-neutral-800);
            background-color: var(--app-neutral-200);
            outline: var(--app-button-outline-width-focus) solid
                var(--app-neutral-800);
        }
    }

    &.btn-success {
        color: var(--app-text-icon-primary-white);
        border-color: var(--app-success-color);
        background-color: var(--app-success-color);

        i {
            background-color: var(--app-text-icon-primary-white);
        }

        &:hover {
            color: var(--app-text-icon-primary-white);
            border-color: var(--app-success-color-hover);
            background-color: var(--app-success-color-hover);
        }

        &:active {
            color: var(--app-text-icon-primary-white);
            border-color: var(--app-success-color-pressed);
            background-color: var(--app-success-color-pressed);
        }

        &:disabled {
            color: var(--app-text-icon-disabled-black);
            border-color: var(--app-neutral-200);
            background-color: var(--app-neutral-200);
            opacity: var(--app-button-opacity-disabled);

            i {
                background-color: var(--app-text-icon-disabled-black);
            }
        }

        &:focus-visible {
            color: var(--app-text-icon-primary-white);
            border-color: var(--app-success-color-hover);
            background-color: var(--app-success-color-hover);
            outline: var(--app-button-outline-width-focus) solid
                var(--app-success-color-hover);
        }
    }

    &.btn-warning {
        color: var(--app-text-icon-primary-white);
        border-color: var(--app-warning-color);
        background-color: var(--app-warning-color);

        i {
            background-color: var(--app-text-icon-primary-white);
        }

        &:hover {
            color: var(--app-text-icon-primary-white);
            border-color: var(--app-warning-color-hover);
            background-color: var(--app-warning-color-hover);
        }

        &:active {
            color: var(--app-text-icon-primary-white);
            border-color: var(--app-warning-color-pressed);
            background-color: var(--app-warning-color-pressed);
        }

        &:disabled {
            color: var(--app-text-icon-disabled-black);
            border-color: var(--app-neutral-200);
            background-color: var(--app-neutral-200);
            opacity: var(--app-button-opacity-disabled);

            i {
                background-color: var(--app-text-icon-disabled-black);
            }
        }

        &:focus-visible {
            color: var(--app-text-icon-primary-white);
            border-color: var(--app-warning-color-hover);
            background-color: var(--app-warning-color-hover);
            outline: var(--app-button-outline-width-focus) solid
                var(--app-warning-color-hover);
        }
    }

    // for button link (isBtn)
    &.link-disabled {
        color: var(--app-text-icon-disabled-black);
        border-color: var(--app-neutral-200);
        background-color: var(--app-neutral-200);

        i {
            background-color: var(--app-text-icon-disabled-black);
        }

        &:hover,
        &:active,
        &:focus-visible {
            @extend .link-disabled;
        }
    }
}
