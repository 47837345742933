@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/typography";

.app-form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .app-tabs {
        &--tab {
            font-size: var(--app-font-size-14);
            margin-bottom: 1.5rem;
        }
    }
}

// Bootstrap Overrides Start
.form-control {
    font-size: var(--app-font-size-16) !important;
    min-height: var(
        --app-form-input-height
    ); // TODO:: to convert height later temp for textarea

    color: var(--app-text-icon-primary-black);
    border-color: var(--app-neutral-300) !important;

    &:focus {
        border-color: var(--app-primary-color);
        box-shadow: var(--app-form-input-box-shadow);
        outline: var(--app-form-input-outline-width-focus) solid
            var(--app-primary-color) !important;
    }

    &::placeholder {
        color: var(--app-text-icon-secondary-black);
    }
}

.input-group {
    .input-group-text {
        @extend .form-control;
    }
}

.form-label {
    font-size: var(--app-font-size-16);
    color: var(--app-text-icon-primary-black);
    font-weight: var(--app-form-label-font-weight);

    @include text-overflow(1);

    .required {
        color: var(--app-primary-color);
    }

    .counter {
        @extend .label--400;
        color: var(--app-text-icon-disabled-black);

        &.max-limit {
            @extend .required;
        }
    }
}

.form-check {
    .form-check-input {
        width: var(--app-form-checkbox-height);
        height: var(--app-form-checkbox-height);
        box-shadow: none;

        &:checked {
            background-color: var(--app-primary-color);
            border: none;
        }
    }

    .form-check-label {
        @include text-overflow(5);
    }
}

// Bootstrap Overrides End

.app-form-input {
    position: relative;
    min-height: var(
        --app-form-input-height
    ); // TODO:: to convert height later temp for textarea

    &--with-icon {
        &--left {
            padding-left: 3.5rem;
        }

        &--right {
            padding-right: 3.5rem;
        }
    }

    &--icon {
        cursor: pointer;

        &--left {
            @include absolute(50%, 7%);
        }

        &--right {
            cursor: pointer;

            @include absolute(50%, 93%);
        }
    }

    textarea {
        @include scroll-style {
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
}

.app-password-strength-bar {
    display: grid;
    grid-template-columns: 3fr 1fr;

    > div div {
        height: 0.3rem !important;
    }
}
