@import "../../../../../assets/scss/mixins";
@import "../../../../../Auth/pages/RegisterPage/assets/scss/style.scss";

.onboard-page {
    @include default-image-style(
        url("../../../../../assets/images/bg_onboard.svg")
    );

    &--card {
        display: flex;
        align-items: center;
        justify-content: center;

        .card-content {
            width: 90vw;
            height: 90vh;
            background-color: var(--app-text-icon-primary-white);
            box-shadow: var(--app-box-shadow-200);
            display: flex;
            flex-direction: column;
            padding: 5rem;
            position: relative;

            /* &::after {
                @include default-image-style(
                    url("../../../../../assets/images/animals_2.svg")
                );
                @include absolute(-8%, 75%);

                width: 31.7rem;
                height: 17.3rem;
                content: "";
            } */

            &--form {
                display: flex;
                flex: 1;

                &--fields,
                &--payment {
                    @include scroll-style {
                        overflow-x: hidden;
                        overflow-y: auto;
                        max-height: 68vh;
                    }
                }

                &--fields-user {
                    @include scroll-style {
                        overflow-x: hidden;
                        overflow-y: auto;
                        max-height: 55vh;
                    }
                }

                @extend .register-page;

                &--plans {
                    display: grid;
                    grid-template-columns: repeat(3, minmax(25rem, 45rem));
                    justify-content: center;
                    grid-column-gap: 2.4rem;
                    width: 100%;

                    @include scroll-style {
                        overflow-x: hidden;
                        overflow-y: auto;
                        max-height: 60vh;
                    }

                    .subscription-plan-cards__box,
                    .subscription-plan-cards__box-content,
                    .subscription-plan-cards__box-action {
                        gap: var(--app-gap-3);
                    }
                }
            }
        }
    }
}
