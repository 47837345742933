@import "../../../../../assets/scss/mixins";
@import "../../../../../assets/scss/typography";

.documented-card {
    border-radius: 0.4rem;
    background-color: var(--app-text-icon-primary-white);
    padding: 2rem;
    overflow: hidden;
    min-height: 15rem;

    &--header {
        display: flex;
        justify-content: space-between;

        .title {
            @extend .font-weight--semi-bold;
            @extend .headline--200;
            color: var(--app-black-color);
            margin-right: 0.5rem;
        }

        .total-percentage {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            height: 6rem;
            width: 6rem;
            background-color: var(--app-text-icon-primary-black);

            .percentage {
                @extend .font-weight--bold;
                @extend .headline--300;
                color: var(--app-text-icon-primary-white);
            }
        }
    }

    &--info {
        display: flex;
        flex-wrap: wrap;
        gap: var(--app-gap-5);

        .item {
            display: flex;
            flex-direction: column;
            gap: var(--app-gap-2);

            .pill {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 2.4rem;
                border-radius: 10rem;
                padding: 1rem 2rem;
                color: var(--app-text-icon-primary-white);
                background-color: var(--app-secondary-color);

                .percentage {
                    @extend .label--200;
                }

                &.short {
                    background-color: var(--map-dashboard-color-short);
                }

                &.medium {
                    background-color: var(--map-dashboard-color-medium);
                }

                &.long {
                    background-color: var(--map-dashboard-color-long);
                }
            }

            .title-section {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: var(--app-gap-1);

                .count {
                    @extend .label--100;
                    color: var(--app-black-color);
                }
            }
        }
    }
}
