@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

.half-width {
    display: inline-block;
    width: 50%;
}

.auth-layout-container {
    height: 100%;

    &--left-bar {
        max-width: 35rem;
        min-width: 35rem;
        background-color: var(--app-neutral-100);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &::after {
            content: "";

            @include default-image-style(
                url("../../../../../assets/images/bg_auth_left.svg"),
                bottom left,
                auto
            );

            position: absolute;
            inset: 0 0 9rem;
        }

        @media (width <= 767px) {
            max-width: 100%;
        }

        .card {
            padding: 2.5rem 1.5rem;
            border-color: var(--app-neutral-300);
            z-index: var(--app-z-index-normal);
            margin: 0 2rem;

            .auth-button {
                width: 22rem;
            }

            .welcome-text {
                justify-content: center;
                display: flex;
            }
        }
    }

    &--right-bar {
        &__top-image {
            @include default-image-style(
                url("../../../../../assets/images/bg_auth_right.svg"),
                top center
            );

            height: 100%;
            position: absolute;
            bottom: 0;
            width: 100%;
            z-index: var(--app-z-index-bg-image);
        }

        &__bottom-image {
            @include default-image-style(
                url("../../../../../assets/images/animals_1.svg"),
                bottom center,
                auto
            );

            height: 100%;
            position: absolute;
            bottom: 4rem;
            width: 100%;
            z-index: var(--app-z-index-bg-image);

            @media (width <= 1350px) {
                @include default-image-style(
                    url("../../../../../assets/images/animals_1.svg"),
                    bottom center,
                    contain
                );
            }
        }
    }
}

:root {
    --map-card-content-padding: 0.8rem;
    --map-card-action-padding: 0 0.5rem;
    --map-card-border: 0.1rem solid var(--app-neutral-300);
    --map-card-disabled-color: #72727270;
    --map-container-padding: 1rem;
}

.map-detail-page {
    .card-item {
        display: flex;
        flex-direction: column;
        width: 20rem;
        min-height: 15rem;
        flex-shrink: 0;

        &--action {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 2.4rem;
            min-height: 2.4rem;
            padding: var(--map-card-action-padding);
        }

        &--content {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            padding: var(--map-card-content-padding);
        }
    }

    .edit-icon {
        cursor: pointer;

        @include absoluteAll(null, null, 0, 0);
    }

    &--container {
        display: flex;
        flex-direction: column;
        gap: var(--app-gap-4);

        // align-items: flex-start;

        @include scroll-style($height: 0.5rem) {
            overflow-x: auto;
            overflow-y: hidden;
        }

        &--tasks {
            width: max-content;
            display: flex;
            flex-direction: column;
            gap: var(--app-gap-3);

            .task-container {
                display: flex;
                box-shadow: var(--app-box-shadow-100);
                background-color: var(--app-text-icon-primary-white);

                &--item {
                    @extend .card-item;
                    position: relative;

                    &--action {
                        @extend .card-item--action;

                        background-image: var(--app-gradient-neutral-color);
                    }

                    &--content {
                        @extend .card-item--content;

                        .title {
                            @extend .paragraph--200;
                            @extend .font-weight--bold;
                            @include text-overflow(6);
                        }
                    }

                    .display-name {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        background: var(--app-neutral-300);
                        color: var(--app-text-icon-secondary-black);
                        padding: 0.2rem 0.5rem;
                    }

                    &.lock {
                        border: 0.1rem dashed var(--app-black-color);
                    }
                }

                &--sub-tasks {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;

                    .sub-task-container {
                        display: flex;

                        &:not(:last-child) {
                            border-bottom: var(--map-card-border);
                        }

                        &.dragging {
                            box-shadow: var(--app-box-shadow-100);
                            background-color: var(
                                --app-text-icon-primary-white
                            );
                            border: none;
                        }

                        &--item {
                            @extend .card-item;
                            position: relative;

                            height: 15rem;
                            border-left: var(--map-card-border);
                            border-right: var(--map-card-border);

                            &--action {
                                @extend .card-item--action;

                                background-image: var(
                                    --app-gradient-neutral-color
                                );
                            }

                            &--content {
                                @extend .card-item--content;

                                .title {
                                    @extend .paragraph--300;
                                    @extend .font-weight--light;
                                    @include text-overflow(6);
                                }
                            }

                            &--add {
                                display: flex;
                                justify-content: center;
                                margin-bottom: 1rem;
                            }

                            .display-name {
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                background: var(--app-neutral-300);
                                color: var(--app-text-icon-secondary-black);
                                padding: 0.2rem 0.5rem;
                            }

                            &.lock {
                                border: 0.1rem dashed var(--app-black-color);
                            }
                        }

                        // &--task-posts {
                        .task-post-container {
                            display: flex;
                            // flex-grow: 1;
                            align-items: center;
                            padding: var(--map-container-padding);
                            gap: var(--app-gap-3);
                            height: 15rem;
                            background-color: var(
                                --app-text-icon-primary-white
                            );

                            &--item {
                                width: 15rem;
                                height: 13rem;
                                display: flex;
                                flex-direction: column;
                                outline: 0.1rem solid var(--app-neutral-100);
                                background-color: inherit;
                                position: relative;

                                &.active-item {
                                    outline: 0.2rem solid
                                        var(--app-primary-color);
                                }

                                &.filter-item {
                                    outline: 0.2rem solid
                                        var(--app-primary-color);
                                }

                                &.un-filter-item {
                                    background-color: var(
                                        --map-card-disabled-color
                                    );
                                    opacity: 0.5;
                                }

                                &--action {
                                    @extend .card-item--action;

                                    background-image: var(
                                        --app-gradient-neutral-color
                                    );
                                }

                                &--content {
                                    display: flex;
                                    flex-direction: column;
                                    flex-grow: 1;
                                    padding: var(--map-card-content-padding);
                                    gap: var(--app-gap-2);
                                    position: relative;

                                    .title {
                                        @extend .label--300;
                                        @include text-overflow(2);
                                        line-height: var(--app-font-size-16);

                                        &--full-height {
                                            @include text-overflow(6);
                                        }
                                    }

                                    .description {
                                        @extend .paragraph--300;
                                        @extend .font-weight--light;
                                        @include text-overflow(4);
                                    }
                                }

                                .display-name {
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    background: var(--app-neutral-300);
                                    color: var(--app-text-icon-secondary-black);
                                    padding: 0.2rem 0.5rem;
                                }

                                &.lock {
                                    border: 0.1rem dashed var(--app-black-color);
                                }
                            }
                        }

                        // }
                    }
                }
            }

            .task-add {
                display: flex;
                justify-content: center;
                width: 20rem;
            }
        }

        &--separator {
            border: 0.1rem dashed var(--app-text-icon-secondary-black);
        }

        &--action-tasks {
            display: flex;
            margin-bottom: 3rem;

            .action-task-container {
                display: flex;
                height: 16.5rem;
                box-shadow: var(--app-box-shadow-100);
                background-color: var(--app-text-icon-primary-white);
                // flex-grow: 1;

                &--strategy-card {
                    width: 20rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-right: var(--map-card-border);

                    .title {
                        @extend .paragraph--300;
                        @extend .font-weight--bold;
                    }
                }

                &--list-container {
                    display: flex;
                    padding: var(--map-container-padding);
                    gap: var(--app-gap-3);
                    align-items: center;

                    &--item {
                        height: 17rem;
                        width: 21rem;
                        background-color: var(--app-neutral-100);
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        &.active-item {
                            outline: 0.2rem solid var(--app-primary-color);
                        }

                        &--action {
                            @extend .card-item--action;

                            background-image: var(
                                --app-gradient-secondary-color
                            );
                        }

                        &--content {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            flex-grow: 1;
                            padding: var(--map-card-content-padding);
                            gap: var(--app-gap-2);
                            position: relative;

                            .detail {
                                display: flex;
                                flex-direction: column;
                                gap: var(--app-gap-2);

                                .title {
                                    @extend .label--300;
                                    @include text-overflow(2);
                                    line-height: var(--app-font-size-16);

                                    &--full-height {
                                        &--with-user {
                                            @include text-overflow(4);
                                        }
                                        @include text-overflow(7);
                                    }
                                }

                                .description {
                                    @extend .paragraph--300;
                                    @extend .font-weight--light;
                                    @include text-overflow(3);
                                }
                            }
                        }

                        .display-name {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            background: var(--app-neutral-300);
                            color: var(--app-text-icon-secondary-black);
                            padding: 0.2rem 0.5rem;
                        }

                        &.lock {
                            border: 0.1rem dashed var(--app-black-color);
                        }
                    }
                }
            }
        }
    }
}

.task-post-form {
    .vote-color {
        &--indicator {
            display: flex;
            height: var(--app-form-checkbox-height);
            width: var(--app-form-checkbox-height);
            border-radius: 0.25rem;
        }

        &--container {
            background-color: var(--app-neutral-100);
            border-radius: 0.4rem;
            border: var(--map-card-border);

            &--bar {
                display: flex;
                align-items: center;
                gap: var(--app-gap-3);
                height: 2rem;
                width: 35rem;
                .vote-progress {
                    display: flex;
                    flex-grow: 1;
                    height: 100%;
                    background-color: var(--app-neutral-300);
                    border-radius: 0.4rem;

                    .vote-progress-bar {
                        border-radius: 0.4rem;
                    }
                }
            }
            .vote-progress--override {
                width: 4rem;
                display: flex;
                align-items: center;
                gap: var(--app-gap-2);

                &--radio {
                    width: 2rem;
                    height: 2rem;

                    &:checked {
                        accent-color: var(--app-primary-color);
                        background-color: var(--app-primary-color);
                    }
                }
            }
            .percentage {
                @extend .label--200;
                @extend .font-weight--bold;
                min-width: 6rem;
                text-align: end;
            }
        }
    }
}
