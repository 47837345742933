@font-face {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/TitilliumWeb/TitilliumWeb-Light.ttf");
}

@font-face {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/TitilliumWeb/TitilliumWeb-SemiBold.ttf");
}

@font-face {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/TitilliumWeb/TitilliumWeb-Bold.ttf");
}
