@import "../../../../assets/scss/mixins";

.svg-icon {
    width: var(--app-icon-height);
    height: var(--app-icon-height);
    display: block;

    // type image
    &--image {
        @include default-image-style;
        flex-shrink: 0;

        // TODO:: separate image with AppImage (AppAlertBar invisible if we remove display:block but working for other icons)
    }

    // type icon
    &--icon {
        @include default-mask-style;

        background-color: var(--app-neutral-800);
        flex-shrink: 0;
    }

    &--disabled {
        background-color: var(--app-text-icon-disabled-black);
    }

    // size
    &--xs {
        width: var(--app-icon-height-xs);
        height: var(--app-icon-height-xs);
    }

    &--sm {
        width: var(--app-icon-height-sm);
        height: var(--app-icon-height-sm);
    }

    &--lg {
        width: var(--app-icon-height-lg);
        height: var(--app-icon-height-lg);
    }

    &--xl {
        width: var(--app-icon-height-xl);
        height: var(--app-icon-height-xl);
    }

    &--xxl {
        width: var(--app-icon-height-xxl);
        height: var(--app-icon-height-xxl);
    }

    // assets images
    &--logo {
        background-image: url("../../../../assets/images/logo.svg");
        width: 16rem;
        height: 5rem;
    }

    &--sr-media-logo {
        background-image: url("../../../../assets/images/sr_media_logo.svg");
        width: 10rem;
        height: 3.8rem;
    }

    &--spinner {
        background-image: url("../../../../assets/images/spinner.svg");
    }

    &--card-amazon {
        background-image: url("../../../../assets/images/card_amazon.icon.svg");
    }

    &--card-mastercard {
        background-image: url("../../../../assets/images/card_master.icon.svg");
    }

    &--card-visa {
        background-image: url("../../../../assets/images/card_visa.icon.svg");
    }

    // assets icons
    &--tab {
        mask-image: url("../../../../assets/icons/tab.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/tab.icon.svg");
    }

    &--eye {
        mask-image: url("../../../../assets/icons/eye.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/eye.icon.svg");
    }

    &--eye-slash {
        mask-image: url("../../../../assets/icons/eye_slash.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/eye_slash.icon.svg");
    }

    &--info {
        mask-image: url("../../../../assets/icons/info.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/info.icon.svg");
    }

    &--success {
        mask-image: url("../../../../assets/icons/success.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/success.icon.svg");
    }

    &--warning {
        mask-image: url("../../../../assets/icons/warning.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/warning.icon.svg");
        background-color: var(--app-warning-color);
    }

    &--danger {
        mask-image: url("../../../../assets/icons/danger.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/danger.icon.svg");
    }

    &--check {
        mask-image: url("../../../../assets/icons/check.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/check.icon.svg");
    }

    &--close {
        mask-image: url("../../../../assets/icons/close.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/close.icon.svg");
    }

    &--upload {
        mask-image: url("../../../../assets/icons/upload.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/upload.icon.svg");
    }

    &--rocket {
        mask-image: url("../../../../assets/icons/rocket.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/rocket.icon.svg");
    }

    &--description {
        mask-image: url("../../../../assets/icons/description.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/description.icon.svg");
    }

    &--plus {
        mask-image: url("../../../../assets/icons/plus.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/plus.icon.svg");
    }

    &--search {
        mask-image: url("../../../../assets/icons/search.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/search.icon.svg");
    }

    &--arrow-left {
        mask-image: url("../../../../assets/icons/arrow_left.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/arrow_left.icon.svg");
    }

    &--arrow-right {
        mask-image: url("../../../../assets/icons/arrow_right.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/arrow_right.icon.svg");
    }

    &--arrow-up {
        mask-image: url("../../../../assets/icons/arrow_up.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/arrow_up.icon.svg");
    }

    &--arrow-down {
        mask-image: url("../../../../assets/icons/arrow_down.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/arrow_down.icon.svg");
    }

    &--chevron-left {
        mask-image: url("../../../../assets/icons/chevron_left.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/chevron_left.icon.svg");
    }

    &--chevron-right {
        mask-image: url("../../../../assets/icons/chevron_right.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/chevron_right.icon.svg");
    }

    &--chevron-up {
        mask-image: url("../../../../assets/icons/chevron_up.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/chevron_up.icon.svg");
    }

    &--chevron-down {
        mask-image: url("../../../../assets/icons/chevron_down.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/chevron_down.icon.svg");
    }

    &--volume-mute {
        mask-image: url("../../../../assets/icons/volume_mute.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/volume_mute.icon.svg");
    }

    &--volume-up {
        mask-image: url("../../../../assets/icons/volume_up.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/volume_up.icon.svg");
    }

    &--notification {
        mask-image: url("../../../../assets/icons/notification.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/notification.icon.svg");
    }

    &--notification-active {
        mask-image: url("../../../../assets/icons/notification_active.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/notification_active.icon.svg");
    }

    &--notification-inactive {
        mask-image: url("../../../../assets/icons/notification_inactive.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/notification_inactive.icon.svg");
    }

    &--star-full {
        mask-image: url("../../../../assets/icons/star_full.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/star_full.icon.svg");
    }

    &--star-half {
        mask-image: url("../../../../assets/icons/star_half.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/star_half.icon.svg");
    }

    &--star-empty {
        mask-image: url("../../../../assets/icons/star_empty.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/star_empty.icon.svg");
    }

    &--thumb-up {
        mask-image: url("../../../../assets/icons/thumb_up.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/thumb_up.icon.svg");
    }

    &--thumb-down {
        mask-image: url("../../../../assets/icons/thumb_down.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/thumb_down.icon.svg");
    }

    &--lock {
        mask-image: url("../../../../assets/icons/lock.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/lock.icon.svg");
    }

    &--unlock {
        mask-image: url("../../../../assets/icons/unlock.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/unlock.icon.svg");
    }

    &--heart-full {
        mask-image: url("../../../../assets/icons/heart_full.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/heart_full.icon.svg");
    }

    &--heart-empty {
        mask-image: url("../../../../assets/icons/heart_empty.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/heart_empty.icon.svg");
    }

    &--zoom-in {
        mask-image: url("../../../../assets/icons/zoom_in.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/zoom_in.icon.svg");
    }

    &--zoom-out {
        mask-image: url("../../../../assets/icons/zoom_out.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/zoom_out.icon.svg");
    }

    &--tilt-left {
        mask-image: url("../../../../assets/icons/tilt_left.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/tilt_left.icon.svg");
    }

    &--tilt-right {
        mask-image: url("../../../../assets/icons/tilt_right.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/tilt_right.icon.svg");
    }

    &--ellipsis {
        mask-image: url("../../../../assets/icons/ellipsis.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/ellipsis.icon.svg");
    }

    &--filter {
        mask-image: url("../../../../assets/icons/filter.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/filter.icon.svg");
    }

    &--sort {
        mask-image: url("../../../../assets/icons/sort.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/sort.icon.svg");
    }

    &--manually {
        mask-image: url("../../../../assets/icons/manually.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/manually.icon.svg");
    }

    &--priority {
        mask-image: url("../../../../assets/icons/priority.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/priority.icon.svg");
    }

    &--due-date {
        mask-image: url("../../../../assets/icons/due-date.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/due-date.icon.svg");
    }

    &--edit {
        mask-image: url("../../../../assets/icons/edit.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/edit.icon.svg");
    }

    &--delete {
        mask-image: url("../../../../assets/icons/delete.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/delete.icon.svg");
    }

    &--folder-open {
        mask-image: url("../../../../assets/icons/folder_open.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/folder_open.icon.svg");
    }

    &--settings {
        mask-image: url("../../../../assets/icons/settings.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/settings.icon.svg");
    }

    &--exit {
        mask-image: url("../../../../assets/icons/exit.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/exit.icon.svg");
    }

    &--user {
        mask-image: url("../../../../assets/icons/user.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/user.icon.svg");
    }

    &--user-group {
        mask-image: url("../../../../assets/icons/user_group.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/user_group.icon.svg");
    }

    &--drag-n-drop {
        mask-image: url("../../../../assets/icons/drag_n_drop.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/drag_n_drop.icon.svg");
    }

    &--drag-indicator {
        mask-image: url("../../../../assets/icons/drag_indicator.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/drag_indicator.icon.svg");
    }

    &--refresh {
        mask-image: url("../../../../assets/icons/refresh.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/refresh.icon.svg");
    }

    &--print {
        mask-image: url("../../../../assets/icons/print.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/print.icon.svg");
    }

    &--save {
        mask-image: url("../../../../assets/icons/save.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/save.icon.svg");
    }

    &--voting {
        mask-image: url("../../../../assets/icons/voting.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/voting.icon.svg");
    }

    &--calendar {
        mask-image: url("../../../../assets/icons/calendar.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/calendar.icon.svg");
    }

    &--attach {
        rotate: 90deg;
        mask-image: url("../../../../assets/icons/attach.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/attach.icon.svg");
    }

    &--link {
        mask-image: url("../../../../assets/icons/link.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/link.icon.svg");
    }

    &--hand {
        mask-image: url("../../../../assets/icons/hand.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/hand.icon.svg");
    }

    &--sentiment-calm {
        mask-image: url("../../../../assets/icons/sentiment_calm.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/sentiment_calm.icon.svg");
    }

    &--cheer {
        mask-image: url("../../../../assets/icons/cheer.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/cheer.icon.svg");
    }

    &--color {
        mask-image: url("../../../../assets/icons/color.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/color.icon.svg");
    }

    &--clone {
        mask-image: url("../../../../assets/icons/clone.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/clone.icon.svg");
    }

    &--reply {
        mask-image: url("../../../../assets/icons/reply.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/reply.icon.svg");
    }

    &--tag {
        mask-image: url("../../../../assets/icons/tag.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/tag.icon.svg");
    }

    &--map {
        mask-image: url("../../../../assets/icons/map.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/map.icon.svg");
    }

    &--dashboard {
        mask-image: url("../../../../assets/icons/dashboard.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/dashboard.icon.svg");
    }

    &--portfolio {
        mask-image: url("../../../../assets/icons/portfolio.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/portfolio.icon.svg");
    }

    &--vote-on {
        mask-image: url("../../../../assets/icons/vote_on.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/vote_on.icon.svg");
    }

    &--kanban {
        mask-image: url("../../../../assets/icons/kanban.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/kanban.icon.svg");
    }

    &--block {
        mask-image: url("../../../../assets/icons/block.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/block.icon.svg");
    }

    &--flashlight {
        mask-image: url("../../../../assets/icons/flashlight.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/flashlight.icon.svg");
    }

    &--construction {
        mask-image: url("../../../../assets/icons/construction.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/construction.icon.svg");
    }

    &--bomb {
        mask-image: url("../../../../assets/icons/bomb.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/bomb.icon.svg");
    }

    &--flower {
        mask-image: url("../../../../assets/icons/flower.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/flower.icon.svg");
    }

    &--download {
        mask-image: url("../../../../assets/icons/download.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/download.icon.svg");
    }

    &--help {
        mask-image: url("../../../../assets/icons/help.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/help.icon.svg");
    }

    &--mail {
        mask-image: url("../../../../assets/icons/mail.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/mail.icon.svg");
    }

    &--renewal-enable {
        mask-image: url("../../../../assets/icons/renewal_enable.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/renewal_enable.icon.svg");
    }

    &--renewal-disable {
        mask-image: url("../../../../assets/icons/renewal_disable.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/renewal_disable.icon.svg");
    }

    &--arrow_range {
        mask-image: url("../../../../assets/icons/arrow_range.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/arrow_range.icon.svg");
    }

    &--home {
        mask-image: url("../../../../assets/icons/home.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/home.icon.svg");
    }

    &--share {
        mask-image: url("../../../../assets/icons/share.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/share.icon.svg");
    }

    &--arrow-percentage_up {
        mask-image: url("../../../../assets/icons/arrow_percentage_up.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/arrow_percentage_up.icon.svg");
    }

    &--arrow-percentage_down {
        mask-image: url("../../../../assets/icons/arrow_percentage_down.icon.svg");
        -webkit-mask-image: url("../../../../assets/icons/arrow_percentage_down.icon.svg");
    }
}

.svg-icon-container {
    position: relative;

    .svg-icon-background {
        @include absolute(50%, 50%);
        border-radius: 50%;

        width: calc(var(--app-icon-height) * 1.3);
        height: calc(var(--app-icon-height) * 1.3);

        // size
        &--xs {
            width: calc(var(--app-icon-height-xs) * 1.3);
            height: calc(var(--app-icon-height-xs) * 1.3);
        }

        &--sm {
            width: calc(var(--app-icon-height-sm) * 1.3);
            height: calc(var(--app-icon-height-sm) * 1.3);
        }

        &--lg {
            width: calc(var(--app-icon-height-lg) * 1.3);
            height: calc(var(--app-icon-height-lg) * 1.3);
        }

        &--xl {
            width: calc(var(--app-icon-height-xl) * 1.3);
            height: calc(var(--app-icon-height-xl) * 1.3);
        }

        &--xxl {
            width: calc(var(--app-icon-height-xxl) * 1.3);
            height: calc(var(--app-icon-height-xxl) * 1.3);
        }

        // assets icons
        &--warning {
            background-color: var(--app-warning-color-bg);
        }
    }

    &--success {
        background-color: var(--app-success-color);
        width: fit-content;
        border-radius: 100%;
        padding: 0.1rem;
        margin-top: 0.5rem;

        .svg-icon {
            background-color: var(--app-text-icon-primary-white);
        }
    }
}
