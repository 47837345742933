@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/typography";

@mixin modal-styles {
    padding: var(--app-modal-padding);

    .modal-header {
        align-items: start;
        justify-content: space-between;
        padding: var(--app-modal-padding);

        .modal-title {
            .title {
                @extend .headline--200;
                @extend .font-weight--semi-bold;

                @include text-overflow(2);
            }
        }
    }

    .modal-body {
        padding: var(--app-modal-padding);

        @include scroll-style {
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    .modal-footer {
        padding: var(--app-modal-padding);
    }
}

.app-base-modal {
    .modal-dialog {
        .modal-content {
            border: var(--app-modal-border);

            @include modal-styles;
        }
    }
}

.app-drawer {
    box-shadow: var(--app-box-shadow-200);

    &.offcanvas {
        z-index: unset;
    }

    &.offcanvas-start,
    &.offcanvas-end {
        top: var(--app-header-height);
        border-top-left-radius: 0.4rem;
        width: 50rem;
    }
    &.app-drawer-with-shadow {
        top: var(--app-modal-top-spacing);
        padding: 0;

        .modal-header {
            background: var(--app-gradient-primary-color);
            padding: 1.5rem;
            border-top-left-radius: 0.4rem;
        }

        .modal-body,
        .modal-footer {
            padding: 1.5rem;
        }
    }

    &.app-drawer-stick-header {
        top: var(--app-header-height);
    }

    @include modal-styles;
}
