@import "../../../../../assets/scss/mixins";
@import "../../../../../assets/scss/typography";
.react-datepicker-popper {
    z-index: var(--app-z-index-dropdown);

    .react-datepicker {
        box-shadow: var(--app-box-shadow-100);
        border: none;
        width: 28rem;
        font-family: inherit;
        border-radius: 0.7rem;
        .react-datepicker__navigation {
            height: 2.5rem;
            width: 2.5rem;
            background: var(--app-text-icon-primary-white);
            border: 0.2rem solid var(--app-neutral-300);
            border-radius: 0.4rem;
            top: 1rem;
            &.react-datepicker__navigation--previous {
                left: 1rem;
            }
            &.react-datepicker__navigation--next {
                right: 1rem;
            }
            .react-datepicker__navigation-icon--previous {
                position: initial;
                &::before {
                    top: 0.7rem;
                    right: 0.5rem;
                    height: 0.7rem;
                    width: 0.7rem;
                    border-width: 0.1rem 0.1rem 0 0;
                    border-color: var(--app-text-icon-primary-black);
                }
            }
            .react-datepicker__navigation-icon--next {
                position: initial;
                &::before {
                    top: 0.7rem;
                    left: 0.5rem;
                    height: 0.7rem;
                    width: 0.7rem;
                    border-width: 0.1rem 0.1rem 0 0;
                    border-color: var(--app-text-icon-primary-black);
                }
            }
        }
        .react-datepicker__month-container {
            width: 100%;
            .react-datepicker__header {
                background: transparent;
                border: none;
                .react-datepicker__current-month {
                    color: var(--app-text-icon-primary-black);
                    @extend .paragraph--100;
                    @extend .font-weight--bold;
                    margin-bottom: 1rem;
                }
                .react-datepicker__day-names {
                    .react-datepicker__day-name {
                        @extend .paragraph--300;
                        color: var(--app-text-icon-primary-black);
                        width: 3.6rem;
                    }
                }
            }
            .react-datepicker__month {
                .react-datepicker__week {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    .react-datepicker__day {
                        color: var(--app-text-icon-primary-black);
                        @extend .paragraph--300;
                        @extend .font-weight--bold;
                        height: 2.3rem;
                        width: 2.3rem;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            background: var(--app-primary-color-bg);
                        }
                        &.react-datepicker__day--selected,
                        &.react-datepicker__day--keyboard-selected {
                            background: var(--app-primary-color);
                            color: var(--app-text-icon-primary-white);
                        }
                        &.react-datepicker__day--outside-month {
                            @extend .font-weight--light;
                        }
                        &.react-datepicker__day--today {
                            background: transparent;
                            color: var(--app-primary-color);
                        }
                    }
                }
            }
        }
    }
}
