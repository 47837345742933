@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

// with BEM technique
.subscription-company-info {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.4rem;

    .content {
        display: flex;
        flex-direction: column;
        gap: var(--app-gap-2);
        flex: 0 0 50%;
        padding: 0 1.6rem 1.6rem 0;

        .label {
            @extend .headline--300;
            @extend .font-weight--bold;
            @include text-overflow(1);
            color: var(--app-text-icon-primary-black);
        }

        .value {
            @extend .paragraph--100;
            @extend .font-weight--bold;
            @include text-overflow(1);
            color: var(--app-text-icon-disabled-black);
        }
    }

    .edit-btn {
        position: absolute;
        @include absoluteAll(null, null, 0, 0);
    }
}
