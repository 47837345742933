@import "../../../../assets/scss/mixins";

.app-header {
    background-color: var(--app-text-icon-primary-white);
    box-shadow: var(--app-box-shadow-100);
    height: var(--app-header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    position: sticky;
    z-index: var(--app-z-index-sticky);
    top: 0;

    &--main {
        display: flex;
        align-items: center;

        .header-logo {
            margin: 0 2rem;
            width: auto;
            height: 5rem;
            cursor: pointer;
        }

        .header-search {
            margin: 0 2rem;
            width: 25rem;
        }
    }

    &--center {
        display: flex;
        align-items: center;
        @media (width >= 1199px) {
            margin-right: 15rem;
        }
    }

    &--action {
        display: flex;
        align-items: center;

        &--profile {
            display: flex;

            .header-profile {
                margin-left: 2rem;

                .header-menu-action {
                    display: none;
                    z-index: var(--app-z-index-popover);
                }

                &:hover {
                    .header-menu-action {
                        background-color: var(--app-text-icon-primary-white);
                        box-shadow: var(--app-box-shadow-100);
                        width: 25rem;
                        padding: 1.5rem;
                        display: flex;
                        flex-direction: column;
                        right: 0.5rem;

                        .menu-label {
                            display: flex;
                            align-items: center;
                            font-weight: var(--app-font-weight-semi-bold);
                            font-size: var(--app-font-size-16);

                            .title {
                                @include text-overflow(1);
                            }
                        }

                        .menu-item {
                            display: flex;
                            align-items: center;
                            font-weight: var(--app-font-weight-light);
                            font-size: var(--app-font-size-14);

                            .title {
                                @include text-overflow(1);
                            }

                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    .header-separator {
        background-color: var(--app-neutral-300);
        height: 3.2rem;
        width: 0.1rem;
    }
}
