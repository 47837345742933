@import "../../../../../assets/scss/typography";

.workspace-page {
    &--form {
        .user-permission {
            display: flex;
            flex-direction: column;
            gap: var(--app-gap-3);

            &--item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &--content {
                    @extend .paragraph--200;
                    @extend .font-weight--semi-bold;
                }

                &--action {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}
