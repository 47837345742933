@import "../../../../../assets/scss/typography";
@import "../../../../../assets/scss/mixins";

// with BEM technique
.subscription-overview-page {
    display: flex;
    flex-direction: column;
    gap: var(--app-gap-5);

    &__header {
        display: flex;
        flex-wrap: wrap;
        gap: var(--app-gap-5);
    }

    &__box {
        display: flex;
        padding: 1.6rem;
        border-radius: 0.4rem;
        box-shadow: var(--app-box-shadow-100);
        background-color: var(--app-text-icon-primary-white);

        &--primary {
            height: 6.4rem;
            align-items: center;
            gap: var(--app-gap-4);
        }

        &--secondary {
            flex-direction: column;
            min-height: 31rem;
            position: relative;
        }
    }

    &__title {
        @extend .headline--200;
        @extend .font-weight--bold;
        @include text-overflow(1);
    }
}
